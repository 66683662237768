import * as BalenaSdk from 'balena-sdk';
import keyBy from 'lodash/keyBy';
import { API_ENDPOINT, getExpanded } from '../../api-utils';
import { useQueries } from 'react-query';
import { useCallback, useMemo, useState } from 'react';
import { getDeviceTypeJsons } from './queries';
import {
	getDockerArtifact,
	getSupportedOsTypes,
	getSupportedOsVersions,
} from '../../shared/DownloadImageModal/utils';
import {
	DeviceType,
	DownloadImageModal,
} from '../../shared/DownloadImageModal';
import {
	ButtonWithTracking,
	useAnalyticsContext,
} from '@balena/ui-shared-components';
import { DeviceTypeWithInstructions } from '../../context/DeviceTypesContextProvider';

interface DownloadImageProps {
	label: string | JSX.Element;
	startIcon?: JSX.Element;
	application: BalenaSdk.Application;
	compatibleDeviceTypes: DeviceTypeWithInstructions[];
	defaultDeviceType: string;
	releaseId?: number;
}

export const DownloadImage = ({
	label,
	startIcon,
	application,
	compatibleDeviceTypes,
	defaultDeviceType,
	releaseId,
}: DownloadImageProps) => {
	const [showDownloadModal, setShowDownloadModal] = useState(false);
	const { state: analyticsState } = useAnalyticsContext();
	const appType = getExpanded(application.application_type);
	const [{ data: osVersions }, { data: deviceTypeJsonIndex }] = useQueries([
		{
			queryKey: [
				'getSupportedOsVersions',
				application.id,
				defaultDeviceType,
				appType,
			],
			queryFn: async () => {
				try {
					return await getSupportedOsVersions(
						application.id,
						[defaultDeviceType],
						appType,
					);
				} catch (err) {
					console.error(err);
				}
			},
		},
		{
			queryKey: ['deviceTypeJson'],
			queryFn: async () => {
				try {
					const deviceTypeJsons = await getDeviceTypeJsons();
					return keyBy(deviceTypeJsons, 'slug');
				} catch (err) {
					console.error('Device type jsons Error: ', err);
				}
			},
		},
	]);

	const memoizedDeviceTypes: DeviceType[] = useMemo(() => {
		if (!compatibleDeviceTypes || !deviceTypeJsonIndex) {
			return [];
		}
		return compatibleDeviceTypes.map((dt) => {
			const deviceTypeJson =
				deviceTypeJsonIndex && deviceTypeJsonIndex[dt.slug];
			return {
				...(deviceTypeJson != null && {
					// TODO: Reduce these & finaly drop this function, once the modal
					// no longer depends on the device-type.json properties
					imageDownloadAlerts: deviceTypeJson.imageDownloadAlerts,
					// We use the deviceTypeJson.instructions only as a fallback in case we failed
					// to populate the dt.instructions from the contract.
					instructions: deviceTypeJson.instructions,
					stateInstructions: deviceTypeJson.stateInstructions,
					options: deviceTypeJson.options,
					yocto: deviceTypeJson.yocto,
				}),
				...dt,
			};
		});
	}, [compatibleDeviceTypes, deviceTypeJsonIndex]);

	const defaultDt = useMemo(
		() => memoizedDeviceTypes.find((dt) => dt.slug === defaultDeviceType),
		[defaultDeviceType, memoizedDeviceTypes],
	);

	const getDockerArtifactCallback = useCallback(
		(slug: string, version: string) => getDockerArtifact(slug, version),
		[],
	);
	const onCloseCallback = useCallback(() => {
		analyticsState.webTracker?.track?.('Download Image Modal Closed');
		setShowDownloadModal(false);
	}, [analyticsState.webTracker]);

	const getSupportedOsVersionsCallback = useCallback(async () => {
		const deviceTypeSlugs = compatibleDeviceTypes?.map((dt) => dt.slug) ?? [];
		return await getSupportedOsVersions(
			application.id,
			deviceTypeSlugs,
			appType,
		);
	}, [appType, application.id, compatibleDeviceTypes]);

	const getSupportedOsTypeCallback = useCallback(
		async (applicationId: number, deviceTypeSlug: string) =>
			await getSupportedOsTypes(applicationId, [deviceTypeSlug]),
		[],
	);

	if (!defaultDt) {
		return null;
	}

	return (
		<>
			<ButtonWithTracking
				eventName="Download Image Modal Opened"
				color="customBlue"
				variant="contained"
				onClick={() => {
					setShowDownloadModal(true);
				}}
				startIcon={startIcon}
				sx={{ color: 'white' }}
			>
				{label ?? 'Get Started'}
			</ButtonWithTracking>
			<DownloadImageModal
				open={showDownloadModal}
				compatibleDeviceTypes={memoizedDeviceTypes}
				applicationId={application.id}
				releaseId={releaseId}
				downloadUrl={`${API_ENDPOINT}/download`}
				onClose={onCloseCallback}
				initialDeviceType={defaultDt}
				initialOsVersions={osVersions}
				getDockerArtifact={getDockerArtifactCallback}
				getSupportedOsVersions={getSupportedOsVersionsCallback}
				getSupportedOsTypes={getSupportedOsTypeCallback}
				isInitialDefault
			/>
		</>
	);
};

import { useLocation, useParams } from 'react-router-dom';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import {
	CustomHelmet,
	ButtonWithTracking,
	ButtonWithTrackingProps,
	Material,
} from '@balena/ui-shared-components';
import { Share } from './Share';
import BalenaWhiteIcon from '../assets/balena_white_icon.svg';
import React, { isValidElement, useMemo } from 'react';
import { BalenaSdk } from '../api-utils';
const { Avatar, Box, Chip, Container, Grid, Typography } = Material;

interface EntityHeaderProps {
	entity: {
		logo: string;
		title: JSX.Element | string;
		authorElement: JSX.Element | string | undefined;
		author: string | undefined;
		description: string;
		logoVariant: Material.AvatarProps['variant'];
		price: string;
		isOfClass: BalenaSdk.Application['is_of__class'];
	};
	actionButton?: JSX.Element | ButtonWithTrackingProps | false;
	bgcolor?: React.ComponentProps<typeof Box>['bgcolor'];
}

export const EntityHeader: React.FC<
	React.PropsWithChildren<EntityHeaderProps>
> = ({ entity, actionButton, bgcolor, children }) => {
	const { name } = useParams();
	const { pathname } = useLocation();

	const paths = pathname.split('/');

	const breadcrumbs = [
		{ label: 'Home', link: '/' },
		{
			label: `${paths[1].charAt(0).toUpperCase()}${paths[1].substring(1)}`
				.split('-')
				.join(' '),
			link: `/${paths[1]}`,
		},
		{
			label: name ?? '',
		},
	];

	const capitalizedClass = useMemo(() => {
		return entity.isOfClass.charAt(0).toUpperCase() + entity.isOfClass.slice(1);
	}, [entity.isOfClass]);

	const meta = {
		title: `${name} ${capitalizedClass} | balena`,
		description: `${name} is a ${capitalizedClass} on balenaHub.`,
		properties: {
			'og:title': `${name} ${capitalizedClass} | balena`,
			'og:description': `${name} is a ${capitalizedClass} on balenaHub.`,
			'og:image': entity.logo,
			'og:url': window.location.href,
			'twitter:title': `${name} ${capitalizedClass} | balena`,
			'twitter:description': `${name} is a ${capitalizedClass} on balenaHub.`,
			'twitter:image': entity.logo,
		},
	};

	return (
		<>
			<CustomHelmet {...meta} />
			<Box bgcolor={bgcolor} py={2}>
				<Box
					display="flex"
					flexDirection={['column', 'row']}
					justifyContent="space-between"
					mx={[3, 3, 5]}
				>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
					<Share description={entity.description} />
				</Box>
				<Container disableGutters maxWidth="xl">
					<Grid container px={[3, 4]} mt={5}>
						<Grid
							item
							display="flex"
							alignItems="center"
							justifyContent={['center', 'flex-start']}
							xs={12}
							sm={12}
							md={7}
							lg={8}
							flexDirection={['column', 'row']}
						>
							<Avatar
								src={entity.logo}
								sx={{
									width: 140,
									height: 140,
									mx: 3,
									img: { objectFit: 'cover' },
								}}
								variant={entity.logoVariant ?? 'circular'}
							/>
							<Typography variant="h1" alignItems="center">
								{entity.title}
								{entity.authorElement}
								<Typography variant="body1" mt={2} color="secondary">
									{entity.description}
								</Typography>
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={5}
							lg={4}
							display="flex"
							flexDirection={['row', 'row', 'column']}
							alignItems={['center', 'center', 'flex-end']}
							justifyContent={['space-between', 'space-between', undefined]}
							my={[2, 2, 'auto']}
						>
							{entity.price && (
								<Chip
									label={entity.price}
									variant="outlined"
									sx={(theme) => ({
										borderColor: theme.palette.text.primary,
										width: 'fit-content',
										mb: [0, 0, '65px'],
									})}
								/>
							)}
							{actionButton ? (
								isValidElement(actionButton) ? (
									actionButton
								) : (
									<ButtonWithTracking
										color="customBlue"
										variant="contained"
										startIcon={<img src={BalenaWhiteIcon} alt="balena icon" />}
										sx={{ color: 'white' }}
										{...(actionButton as ButtonWithTrackingProps)}
									/>
								)
							) : null}
						</Grid>
						{children}
					</Grid>
				</Container>
			</Box>
		</>
	);
};

import { useQueries } from 'react-query';
import { SectionResource } from '../../shared/SectionResource';
import { SectionText } from '../../shared/SectionText';
import { useDeviceTypesContext } from '../../context/DeviceTypesContextProvider';
import TitleBackground from '../../assets/balenahub_pattern_dark.svg';
import { appToCardProps } from '../../utils/application';
import { getApplications } from './queries';
import logo from '../../assets/balenahub_logo_small.png';
import {
	AnimatedText,
	CustomHelmet,
	Material,
} from '@balena/ui-shared-components';
const { Box, Container, Typography } = Material;
const ITEMS_PER_CATEGORY = 9;

const SectionContainer: React.FC<
	React.PropsWithChildren<{
		paletteName: keyof Material.PaletteOptions;
		paletteColor: keyof Material.PaletteColor;
	}>
> = ({ paletteName, paletteColor, children }) => {
	return (
		<Box
			pt={[3, 3, 3, 4]}
			pb={4}
			px={[3, 3, 4, 5]}
			sx={(theme) => {
				const color = (theme.palette[paletteName] as Material.PaletteColor)[
					paletteColor
				];
				return {
					background: {
						xs: `linear-gradient(to bottom, ${color} 0, ${color} 310px, ${theme.palette.background.default} 310px, ${theme.palette.background.default} 100%)`,
						md: `linear-gradient(to bottom, ${color} 0, ${color} 390px, ${theme.palette.background.default} 390px, ${theme.palette.background.default} 100%)`,
						lg: `linear-gradient(to bottom, ${color} 0, ${color} 400px, ${theme.palette.background.default} 400px, ${theme.palette.background.default} 100%)`,
						xl: `linear-gradient(to bottom, ${color} 0, ${color} 350px, ${theme.palette.background.default} 350px, ${theme.palette.background.default} 100%)`,
					},
				};
			}}
		>
			{children}
		</Box>
	);
};

const titleWords = [
	'smart parking',
	'EV charging',
	'industrial IoT',
	'prop tech',
	'digital signage',
	'fibre optics',
	'solar energy',
];

export const Home: React.FC<{}> = () => {
	const eventOrigin = 'home';
	const { state } = useDeviceTypesContext();

	const [
		{ data: latestApps },
		{ data: latestBlocks },
		{ data: helpPowerResearchFleet },
		{ data: foldForCovidArm },
		{ data: ribbitThree },
	] = useQueries([
		{
			queryKey: 'latestApps',
			queryFn: async () =>
				await getApplications({ is_of__class: 'app' }, ITEMS_PER_CATEGORY),
		},
		{
			queryKey: 'latestBlocks',
			queryFn: async () =>
				await getApplications({ is_of__class: 'block' }, ITEMS_PER_CATEGORY),
		},
		{
			queryKey: ['latestFleetsForGood', 1918419],
			queryFn: async () =>
				await getApplications({ id: 1918419 }, ITEMS_PER_CATEGORY),
		},
		{
			queryKey: ['latestFleetsForGood', 1635296],
			queryFn: async () =>
				await getApplications({ id: 1635296 }, ITEMS_PER_CATEGORY),
		},
		{
			queryKey: ['latestFleetsForGood', 1994686],
			queryFn: async () =>
				await getApplications({ id: 1994686 }, ITEMS_PER_CATEGORY),
		},
	]);

	const appSections = [
		{
			title: 'Latest',
			entries:
				latestApps?.map((app) =>
					appToCardProps(app, state.deviceTypes, false),
				) ?? [],
			sectionText: {
				mainText: 'Apps',
				subText:
					'Solve real-world physical computing challenges immediately with ready-to-deploy applications that run directly on fleets of small devices. Deploy in just a few clicks and manage centrally over the air using balenaCloud.',
			},
			orderedRedirect: {
				href: '/apps',
				title: 'VIEW ALL',
				state: { $orderby: 'created_at desc' },
			},
			redirect: { href: '/apps', title: 'Explore' },
		},
	];

	const blockSections = [
		{
			title: 'Latest',
			entries:
				latestBlocks?.map((app) =>
					appToCardProps(app, state.deviceTypes, false),
				) ?? [],
			sectionText: {
				mainText: 'Blocks',
				subText:
					'Build with blocks to instantly satisfy common requirements and jump-start your product development.',
			},
			orderedRedirect: {
				href: '/blocks',
				title: 'VIEW ALL',
				state: { $orderby: 'created_at desc' },
			},
			redirect: { href: '/blocks', title: 'Explore' },
		},
	];

	const fleetsForGoodSection = [
		{
			title: '',
			entries:
				(helpPowerResearchFleet &&
					foldForCovidArm &&
					ribbitThree &&
					[...helpPowerResearchFleet, ...foldForCovidArm, ...ribbitThree]?.map(
						(app) => appToCardProps(app, state.deviceTypes, false),
					)) ??
				[],
			sectionText: {
				mainText: 'Fleets for Good',
				subText: 'Join together to make an impact.',
			},
			redirect: { href: '/fleets-for-good', title: 'Explore' },
		},
	];

	const meta = {
		title:
			'balenaHub - IoT embedded device apps, edge computing resources, and developer tools',
		description:
			'Accelerate your product development with ready-to-run resources and example applications, helping you to complete prototypes and scale to production faster.',
		properties: {
			'og:title':
				'balenaHub - IoT embedded device apps, edge computing resources, and developer tools',
			'og:description': `Accelerate your product development with ready-to-run resources and example applications, helping you to complete prototypes and scale to production faster.`,
			'og:image': logo,
			'og:url': window.location.href,
			'twitter:title': `balenaHub - IoT embedded device apps, edge computing resources, and developer tools`,
			'twitter:description': `Accelerate your product development with ready-to-run resources and example applications, helping you to complete prototypes and scale to production faster.`,
			'twitter:image': logo,
		},
	};

	return (
		<>
			<CustomHelmet {...meta} />
			<SectionText
				mainText={
					<Typography variant="h1" mb={4}>
						<Box
							display="flex"
							flexDirection={['column', 'column', 'row']}
							justifyContent="center"
							alignItems="center"
							sx={{ animationDuration: '1s' }}
						>
							<Box pr={3}>Scale your</Box>
							<AnimatedText
								animationTimeout={2000}
								words={titleWords}
								animationType="collapse"
							/>
						</Box>
						product with balena
					</Typography>
				}
				subText={
					<Typography variant="bodyLarge" fontWeight={600}>
						IoT embedded device apps, edge computing resources, and developer
						tools.
					</Typography>
				}
				sx={{
					backgroundColor: '#162835',
					backgroundImage: `url(${TitleBackground})`,
					color: 'white',
					backgroundSize: '65%',
					backgroundPosition: 'center',
					paddingY: { xs: 5, md: 5, lg: 6 },
					textAlign: 'center',
				}}
			/>
			<SectionContainer paletteName="customBlue" paletteColor="xlight">
				<Container maxWidth="xl">
					<SectionResource
						eventOrigin={eventOrigin}
						sections={appSections}
						itemsPerPage={ITEMS_PER_CATEGORY}
						truncateCardDescriptions
					/>
				</Container>
			</SectionContainer>
			<SectionContainer paletteName="customGreen" paletteColor="xlight">
				<Container maxWidth="xl">
					<SectionResource
						eventOrigin={eventOrigin}
						sections={blockSections}
						itemsPerPage={ITEMS_PER_CATEGORY}
						truncateCardDescriptions
					/>
				</Container>
			</SectionContainer>
			<SectionContainer paletteName="customPurple" paletteColor="xlight">
				<Container maxWidth="xl">
					<SectionResource
						sections={fleetsForGoodSection}
						itemsPerPage={ITEMS_PER_CATEGORY}
						truncateCardDescriptions
					/>
				</Container>
			</SectionContainer>
		</>
	);
};

import { Material } from '@balena/ui-shared-components';

const { Box, Card, Typography } = Material;

interface OverviewProps {
	overview: {
		title: string;
		informationList: Array<{
			label: string;
			value: JSX.Element | string | number | undefined;
		}>;
	};
}

export const Overview: React.FC<OverviewProps> = ({ overview }) => {
	return (
		<Card>
			<Typography variant="h5" fontWeight={600} mb={2}>
				{overview.title}
			</Typography>
			{overview.informationList.map((information, index) =>
				!!information.value ? (
					<Box key={index}>
						<Typography variant="body2" mb={1} fontWeight={600}>
							{information.label}
						</Typography>
						{typeof information.value === 'string' ? (
							<Typography variant="body1" component="span" ml={2}>
								{information.value}
							</Typography>
						) : (
							information.value
						)}
					</Box>
				) : null,
			)}
		</Card>
	);
};

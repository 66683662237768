import { RouterLinkWithTracking, Material } from '@balena/ui-shared-components';
const { Typography, Breadcrumbs: MUIBreadcrumbs } = Material;

export const Breadcrumbs: React.FC<{
	breadcrumbs: Array<{ label: string; link?: string }>;
	sx?: Material.SxProps;
}> = ({ breadcrumbs, sx }) => {
	return (
		<MUIBreadcrumbs sx={{ mt: 3, ...sx }}>
			{breadcrumbs.map(
				(breadcrumb: { label: string; link?: string }, index) => (
					<Typography
						sx={{
							...(index !== breadcrumbs.length - 1
								? { ':hover': { textDecoration: 'underline' } }
								: {}),
							...(index === breadcrumbs.length - 1
								? { fontWeight: 'bold' }
								: {}),
						}}
						key={index}
						color="secondary"
					>
						{breadcrumb.link ? (
							<RouterLinkWithTracking
								to={breadcrumb.link}
								key={index}
								data-underline="false"
								style={{ color: 'inherit' }}
							>
								{breadcrumb.label}
							</RouterLinkWithTracking>
						) : (
							breadcrumb.label
						)}
					</Typography>
				),
			)}
		</MUIBreadcrumbs>
	);
};

import { useQueries } from 'react-query';
import { BalenaSdk } from '../../api-utils';
import { useDeviceTypesContext } from '../../context/DeviceTypesContextProvider';
import { SectionResource } from '../../shared/SectionResource';
import { SectionText } from '../../shared/SectionText';
import {
	APPLICATION_BACKGROUND,
	appToCardProps,
	ITEMS_PER_PAGE,
} from '../../utils/application';
import TitleBackground from '../../assets/balenahub_pattern_light.svg';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Filter,
	Filters,
	FilterType,
	ReturnFilters,
} from '../../shared/Filters';
import { OrderBy } from '../../shared/OrderBy';
import { getApplicationList, getApplicationListTotalItems } from './queries';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/balenahub_logo_small.png';
import { CustomHelmet, Material } from '@balena/ui-shared-components';

const { Box, Container, Grid, Pagination, Typography } = Material;

const generatePineFilters = (data: ReturnFilters) => {
	let $filter: BalenaSdk.PineFilter<BalenaSdk.Application> = {};
	if (data.keywords) {
		$filter = {
			...$filter,
			$contains: [{ $tolower: { $: 'app_name' } }, { $tolower: data.keywords }],
		};
	}
	if (data.works_with.length) {
		$filter = {
			...$filter,
			$or: [
				...data.works_with.map((dtSlug: string) => ({
					should_be_running__release: {
						contract: {
							$contains: dtSlug,
						},
					},
				})),
				{
					is_for__device_type: {
						slug: { $in: data.works_with },
					},
				},
			],
		};
	}
	return $filter;
};

export const ApplicationList: React.FC<{
	isOfClass: BalenaSdk.Application['is_of__class'];
	mainText: string;
	subText: string | JSX.Element;
}> = ({ isOfClass, mainText, subText }) => {
	const { state } = useDeviceTypesContext();
	const { state: routerState } = useLocation();
	const { pathname } = useLocation();
	const paths = pathname.split('/');
	const listName = `${paths[1].charAt(0).toUpperCase()}${paths[1].substring(
		1,
	)}`;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [orderBy, setOrderBy] = useState<string>('app_name asc');
	const [pineFilter, setPineFilter] = useState({});
	const [{ data: totalItems }, { data: applicationList, isLoading }] =
		useQueries([
			{
				queryKey: ['applicationListTotalItems', pineFilter, isOfClass],
				queryFn: async () => {
					try {
						return await getApplicationListTotalItems({
							is_of__class: isOfClass,
							...pineFilter,
						});
					} catch (err) {
						console.error('getApplicationListTotalItems Error: ', err);
					}
				},
			},
			{
				queryKey: [
					'applicationList',
					currentPage,
					orderBy,
					pineFilter,
					isOfClass,
				],
				queryFn: async () => {
					try {
						return await getApplicationList(
							{ is_of__class: isOfClass, ...pineFilter },
							ITEMS_PER_PAGE,
							(currentPage - 1) * ITEMS_PER_PAGE,
							orderBy,
						);
					} catch (err) {
						console.error('getApplicationList Error: ', err);
					}
				},
			},
		]);

	const sections = useMemo(
		() => [
			{
				entries:
					applicationList?.map((application) =>
						appToCardProps(application, state.deviceTypes, false),
					) ?? [],
			},
		],
		[applicationList, state.deviceTypes],
	);

	const memoizedFilters = useMemo(() => {
		const filters = [
			{
				slug: 'keywords',
				title: 'Keywords',
				type: FilterType.keyword,
				value: undefined,
			},
			...(state.deviceTypes
				? [
						{
							slug: 'works_with',
							title: 'Works with',
							type: FilterType.checkbox,
							options: state.deviceTypes!.map((dt) => ({
								label: dt.name,
								value: dt.slug,
								checked: false,
							})),
						},
				  ]
				: []),
		] as Filter[];
		return filters;
	}, [state.deviceTypes]);

	const handleFiltersChange = useCallback(
		(data: ReturnFilters) => setPineFilter(generatePineFilters(data)),
		[setPineFilter],
	);

	useEffect(() => {
		if (routerState?.$orderby) {
			setOrderBy(routerState?.$orderby);
		}
	}, [routerState?.$orderby]);

	const meta = {
		title: `${listName} | balena`,
		description: `List of ${listName} on balenaHub.`,
		properties: {
			'og:title': `${listName} list | balena`,
			'og:description': `List of ${listName} on balenaHub.`,
			'og:image': logo,
			'og:url': window.location.href,
			'twitter:title': `${listName} list | balena`,
			'twitter:description': `List of ${listName} on balenaHub.`,
			'twitter:image': logo,
		},
	};

	return (
		<>
			<CustomHelmet {...meta} />
			<Grid container>
				<Grid item xs={12}>
					<SectionText
						mainText={mainText}
						subText={subText}
						sx={{
							backgroundColor: APPLICATION_BACKGROUND[isOfClass],
							backgroundImage: `url(${TitleBackground})`,
							color: 'text.main',
							backgroundSize: '65%',
							backgroundPosition: 'center',
							paddingY: 1,
							textAlign: 'center',
							height: '420px',
						}}
						showBreadcrumbs
					/>
				</Grid>
				<Container maxWidth="xl" disableGutters sx={{ px: 4 }}>
					<Grid container>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={3} pr={[0, 0, 3, 4]}>
							<Box pt={[2, 2, 0]}>
								<Filters
									onChange={handleFiltersChange}
									filters={memoizedFilters}
								/>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={8}
							lg={8}
							xl={9}
							py={[2, 2, 4]}
							pl={[0, 0, 3, 4]}
						>
							{!isLoading && applicationList?.length === 0 ? (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									height="100%"
								>
									No result
								</Box>
							) : (
								<>
									<Box
										pb={[2, 2, 4]}
										display="flex"
										justifyContent="space-between"
									>
										<Typography variant="h5" fontWeight={600}>
											{listName}
										</Typography>
										<OrderBy
											orderBy={orderBy}
											options={[
												{ label: 'Latest', value: 'created_at desc' },
												{ label: 'Oldest', value: 'created_at asc' },
												{ label: 'A-Z', value: 'app_name asc' },
												{ label: 'Z-A', value: 'app_name desc' },
											]}
											onChange={setOrderBy}
										/>
									</Box>
									<SectionResource
										eventOrigin={`${isOfClass}ApplicationList`}
										sections={sections}
										itemsPerPage={ITEMS_PER_PAGE}
										truncateCardDescriptions
									/>
									{totalItems && totalItems > ITEMS_PER_PAGE && (
										<Pagination
											count={Math.ceil(totalItems / ITEMS_PER_PAGE)}
											onChange={(_event, page) => setCurrentPage(page)}
											shape="rounded"
											sx={{ width: 'fit-content', my: 4, mx: 'auto' }}
										/>
									)}
								</>
							)}
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</>
	);
};

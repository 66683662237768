import { BalenaSdk, getExpanded, sdk } from '../api-utils';

export const filterDeviceTypesByArch = (
	defaultDeviceTypeSlug: string,
	supportedDeviceTypes: string[],
	allDeviceTypes: BalenaSdk.DeviceType[] | undefined,
) => {
	const defaultDt = allDeviceTypes?.find(
		(dt) => dt.slug === defaultDeviceTypeSlug,
	);
	const defaultArch =
		defaultDt && getExpanded(defaultDt.is_of__cpu_architecture)?.slug;
	if (defaultArch) {
		return Array.from(
			new Set([...supportedDeviceTypes, defaultDeviceTypeSlug]),
		).reduce((acc, dt) => {
			const supportedDevice = allDeviceTypes?.find(
				(device) => device.slug === dt,
			);
			if (
				supportedDevice &&
				sdk.models.os.isArchitectureCompatibleWith(
					getExpanded(supportedDevice.is_of__cpu_architecture)!.slug,
					defaultArch,
				)
			) {
				acc.push(supportedDevice);
			}
			return acc;
		}, [] as BalenaSdk.DeviceType[]);
	}

	return Array.from(
		new Set([...supportedDeviceTypes, defaultDeviceTypeSlug]),
	).reduce((acc, dt) => {
		const supportedDevice = allDeviceTypes?.find(
			(device) => device.slug === dt,
		);
		if (supportedDevice) {
			acc.push(supportedDevice);
		}
		return acc;
	}, [] as BalenaSdk.DeviceType[]);
};

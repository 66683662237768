import { useLocation, useParams } from 'react-router-dom';
import {
	MUILinkWithTracking,
	Material,
	IconsMaterial,
} from '@balena/ui-shared-components';
import { useMemo } from 'react';

const { Box, Typography } = Material;

const { FacebookRounded, Twitter, LinkedIn } = IconsMaterial;

export const Share: React.FC<{
	description: string;
}> = ({ description }) => {
	const { id, name } = useParams();
	const { pathname } = useLocation();

	const paths = pathname.split('/');

	const links = useMemo(
		() => [
			{
				icon: <FacebookRounded />,
				href: `https://www.facebook.com/sharer.php?s=100&p[url]=https://hub.balena.io/${paths[1]}/${id}/${name}`,
			},
			{
				icon: <Twitter />,
				href: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
					`I'm installing ${name} from balenaHub; ${
						description?.substring(0, 200) ?? ''
					}`,
				)}&url=https://hub.balena.io/${paths[1]}/${id}/${name}&via=balena_io`,
			},
			{
				icon: <LinkedIn />,
				href: `https://www.linkedin.com/shareArticle/?url=https://hub.balena.io/${paths[1]}/${id}/${name}&mini=true`,
			},
		],
		[name, description, id, paths],
	);

	return (
		<Box mt={3} display="flex">
			<Typography mr={3} color="text.tertiary">
				Share
			</Typography>
			{links.map((l, index) => (
				<MUILinkWithTracking
					href={l.href}
					target="_blank"
					ml={index ? 3 : 0}
					color="text.tertiary"
					key={index}
				>
					{l.icon}
				</MUILinkWithTracking>
			))}
		</Box>
	);
};

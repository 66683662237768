import { useEffect, useState } from 'react';
import {
	ButtonWithTracking,
	MUILinkWithTracking,
	RouterLinkWithTracking,
	useAnalyticsContext,
	theme,
	Material,
	IconsMaterial,
} from '@balena/ui-shared-components';
import hubLogo from './assets/balenahub_logo_white.svg';
import dashboardLogo from './assets/balenacloud_logo_white.svg';

const { Divider, AppBar, Box, Typography, useMediaQuery } = Material;
const { Menu, Close, ChevronRight } = IconsMaterial;

const headerLinks = ['Apps', 'Blocks', 'Fleets for Good'];

interface DashboardLinkProps {
	trackBalenaNavigation: (url: string) => string;
}

const DashboardLink: React.FC<DashboardLinkProps> = ({
	trackBalenaNavigation,
}) => (
	<MUILinkWithTracking
		href={trackBalenaNavigation('https://dashboard.balena-cloud.com/login')}
		target="_blank"
		rel="noreferrer"
	>
		<Box
			px={2}
			height="44px"
			borderRadius="4px"
			display="flex"
			alignItems="center"
			sx={{ background: 'white', color: 'customBlue.dark' }}
		>
			<img
				src={dashboardLogo}
				alt="Link to balenaCloud: IoT Dashboard"
				width="180px"
			/>
			<ChevronRight />
		</Box>
	</MUILinkWithTracking>
);

export const Header: React.FC<{}> = () => {
	const matches = useMediaQuery(theme.breakpoints.up('md'));
	const { state: analyticsState } = useAnalyticsContext();
	const [menuOpen, setMenuOpen] = useState(false);
	useEffect(() => {
		setMenuOpen(false);
	}, [matches]);

	return (
		<AppBar position="fixed" sx={{ bgcolor: 'customBlue.dark' }}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				px={[3, 3, 5]}
				py={2}
			>
				<Box display="flex" flex={2}>
					<RouterLinkWithTracking to="/">
						<Box
							component="img"
							src={hubLogo}
							height="47px"
							width={['150px', '170px', '233px']}
							pt={['10px', '5px', 0]}
							alt="Link to balenaHub: IoT Marketplace homepage"
						/>
					</RouterLinkWithTracking>
				</Box>
				{matches ? (
					<Box display="flex" alignItems="center" maxWidth="550px">
						{headerLinks.map((link, index) => (
							<RouterLinkWithTracking
								to={`/${link.toLowerCase().split(' ').join('-')}`}
								key={index}
								style={{ marginRight: '32px' }}
							>
								<Typography color="white" sx={{ fontSize: '14px' }}>
									{link}
								</Typography>
							</RouterLinkWithTracking>
						))}
						<DashboardLink
							trackBalenaNavigation={analyticsState.trackBalenaNavigation}
						/>
					</Box>
				) : (
					<ButtonWithTracking
						eventName="Mobile Header Menu Opened"
						sx={{ color: 'white', minHeight: 0, minWidth: 0, px: 0 }}
						onClick={() => {
							setMenuOpen(!menuOpen);
						}}
					>
						{menuOpen ? <Close fontSize="large" /> : <Menu fontSize="large" />}
					</ButtonWithTracking>
				)}
			</Box>
			{menuOpen && (
				<Box display="flex" flexDirection="column" mx={6}>
					{headerLinks.map((link, index) => (
						<Box key={index}>
							{!!index && (
								<Divider style={{ width: '100%', borderColor: 'white' }} />
							)}
							<Box
								py={3}
								display="flex"
								width="100%"
								alignItems="center"
								justifyContent="center"
							>
								<RouterLinkWithTracking
									to={`/${link.toLowerCase().split(' ').join('-')}`}
									onClick={() => setMenuOpen(false)}
								>
									<Typography fontSize="small" color="white">
										{link}
									</Typography>
								</RouterLinkWithTracking>
							</Box>
						</Box>
					))}
					<Box py={3} display="flex" justifyContent="center">
						<DashboardLink
							trackBalenaNavigation={analyticsState.trackBalenaNavigation}
						/>
					</Box>
				</Box>
			)}
		</AppBar>
	);
};

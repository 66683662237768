import { BalenaSdk, getExpandedProp } from '../api-utils';
import defaultLogo from '../assets/default-app-logo.svg';
import { SectionCardProps } from '../shared/SectionResource';
import { filterDeviceTypesByArch } from './device-type';
import {
	IconsMaterial,
	Material,
	Truncate,
} from '@balena/ui-shared-components';

const { Typography } = Material;

const { AccountCircle } = IconsMaterial;

export const ITEMS_PER_PAGE = 30;

export enum ApplicationRoutes {
	app = 'apps',
	fleet = 'fleets-for-good',
	block = 'blocks',
}

export const APPLICATION_BACKGROUND: {
	[key in BalenaSdk.Application['is_of__class']]: string;
} = {
	app: 'customBlue.light',
	block: 'customGreen.xlight',
	fleet: 'customPurple.xlight',
};

export const getContract = (
	entity: BalenaSdk.Application,
): BalenaSdk.Contract =>
	JSON.parse(
		getExpandedProp(entity.should_be_running__release, 'contract') ?? '{}',
	);

export const getAppLogo = (entity: BalenaSdk.Application): string => {
	const contract = getContract(entity);
	return contract.assets?.logo?.data?.url ?? defaultLogo;
};

export const getAuthor = (
	entity: BalenaSdk.Application,
	small?: boolean,
): JSX.Element | undefined => {
	const author = getExpandedProp(entity.public_organization, 'name');
	if (!author) {
		return undefined;
	}
	return (
		<Typography
			display="flex"
			alignItems="center"
			variant={small ? 'smallText' : 'body1'}
			component="span"
			color="text.tertiary"
		>
			<AccountCircle
				fontSize={small ? 'small' : 'medium'}
				sx={{ marginLeft: '-2px', marginRight: 1 }}
			/>{' '}
			<Truncate lineCamp={1}>by {author}</Truncate>
		</Typography>
	);
};

export const getWorksWith = (
	entity: BalenaSdk.Application,
	storedDeviceTypes: BalenaSdk.DeviceType[] | undefined,
): BalenaSdk.DeviceType[] | undefined => {
	if (!storedDeviceTypes) {
		return undefined;
	}
	const contract = getContract(entity);
	const deviceTypes =
		contract?.data?.supportedDeviceTypes ??
		getExpandedProp(entity.is_for__device_type, 'slug');

	return filterDeviceTypesByArch(
		getExpandedProp(entity.is_for__device_type, 'slug')!,
		deviceTypes,
		storedDeviceTypes,
	);
};

export const getAppDescription = (entity: BalenaSdk.Application): string => {
	const contract = getContract(entity);
	return contract.description ?? contract.data?.description ?? 'No description';
};

export const getAppPostProvisioning = (
	entity: BalenaSdk.Application,
): string | undefined => {
	const contract = getContract(entity) as BalenaSdk.Contract & {
		'post-provisioning': string;
	};
	return contract['post-provisioning'];
};

export const appToCardProps = (
	entity: BalenaSdk.Application,
	storedDeviceTypes: BalenaSdk.DeviceType[] | undefined,
	small?: boolean,
): SectionCardProps => {
	const applicationCard = {
		title: entity.app_name,
		author: getAuthor(entity, true) ?? '',
		description: getAppDescription(entity),
		logo: getAppLogo(entity),
		link: `/${ApplicationRoutes[entity.is_of__class]}/${entity.id}/${
			entity.app_name
		}`,
	};

	if (small) {
		return applicationCard;
	}
	return Object.assign(applicationCard, {
		imageList: getWorksWith(entity, storedDeviceTypes),
		footerTitle: 'WORKS WITH',
	});
};

import { Material } from '@balena/ui-shared-components';

const { Box, Grid, Typography, useTheme } = Material;

export const ThemeOverrides = () => {
	const theme = useTheme();
	const detectValue = (key: string, value: any) => {
		if (
			typeof value === 'string' &&
			(value.startsWith('#') || value.startsWith('rgb'))
		) {
			return { background: value };
		}
	};

	return (
		<div>
			<Typography variant="h4">Breakpoints</Typography>
			{Object.entries(theme.breakpoints.values).map(
				([key, value]: [string, number]) => (
					<Typography variant="body1">
						{key} : {value}
					</Typography>
				),
			)}
			<Typography variant="h5">Palette</Typography>
			{Object.entries(theme.palette).map(([key, value]: [string, number]) =>
				typeof value === 'object' ? (
					<>
						<Typography variant="h5">{key}</Typography>
						<Grid container>
							{Object.entries(value).map(([k, v]) => {
								return (
									<Grid
										item
										xs={3}
										sx={{
											height: '100px',
											...detectValue(k, v),
										}}
									>
										<Box
											display="flex"
											justifyContent="center"
											alignItems="center"
											height="100%"
										>{`${k} : ${v}`}</Box>
									</Grid>
								);
							})}
						</Grid>
					</>
				) : null,
			)}
		</div>
	);
};

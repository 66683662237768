import * as React from 'react';
import { useQuery } from 'react-query';
import {
	Routes,
	Route,
	useLocation,
	Outlet,
	RouteProps,
	Navigate,
} from 'react-router-dom';
import { BalenaSdk, printConsoleGreeting, sdk } from './api-utils';
import {
	DeviceTypeWithInstructions,
	StoreActions,
	useDeviceTypesContext,
} from './context/DeviceTypesContextProvider';
import { Home } from './views/home';
import { ThemeOverrides } from './ThemeOverrides';
import { ApplicationList } from './views/applicationList';
import { App } from './views/app';
import { Block } from './views/block';
import { Terms } from './views/terms';
import { FleetForGood } from './views/fleetForGood';
import { FleetsForGood } from './views/fleetsForGood';
import {
	useAnalyticsContext,
	Material,
	AnalyticsStoreActions,
	CookiesBanner,
	Cookie,
} from '@balena/ui-shared-components';
import packageJson from '../package.json';
import { createMarketingClient } from 'analytics-client';
import { Dictionary } from 'balena-sdk/typings/utils';

const { Alert, Backdrop, CircularProgress, Typography } = Material;

const isProduction = process.env.NODE_ENV === 'production';

const cookies = {
	essentials: {
		title: 'Essentials',
		description:
			'Essential cookies are necessary for features which are essential to your use of our site or services, such as account login, authentication, and site security.',
		value: true,
		required: true,
	},
	analytics: {
		title: 'Analytics',
		description:
			'Analytics cookies allow us to analyze your visits and actions on our websites, and offer you a more relevant experience.',
		value: false,
	},
};

export const getDeviceTypes = async () => {
	const options: BalenaSdk.PineOptions<BalenaSdk.DeviceType> = {
		$select: ['slug', 'name', 'contract'],
		$expand: {
			is_default_for__application: {
				$select: 'is_archived',
				$expand: {
					application_tag: {
						$select: 'value',
						$filter: {
							tag_key: 'release-policy',
						},
					},
					should_be_running__release: {
						$select: 'release_tag',
						$expand: {
							release_tag: {
								$select: ['tag_key', 'value'],
							},
						},
					},
				},
				$filter: {
					is_host: true,
					is_archived: false,
				},
			},
			is_of__cpu_architecture: {
				$select: 'slug',
			},
		},
		$orderby: {
			name: 'asc',
		},
	};

	const deviceTypes = (await sdk.models.deviceType.getAllSupported(
		options,
	)) as DeviceTypeWithInstructions[];
	for (const deviceType of deviceTypes) {
		// Manually set the logo, since it's already part of the contract
		// which we need to retrieve anyway
		deviceType.logo = deviceType.contract?.assets?.logo?.url;
	}
	await Promise.all(
		deviceTypes.map(async (dt) => {
			try {
				if (!dt.contract) {
					return;
				}
				dt.instructions = await sdk.models.deviceType.getInstructions(
					dt.contract,
				);
			} catch (err) {
				console.log(
					`Error while populating device type instructions for ${dt.slug}`,
					err,
				);
			}
		}),
	);
	return deviceTypes;
};

const routes = (): RouteProps[] => {
	const isDevelopment = process.env.NODE_ENV === 'development';
	const routes = [
		{
			index: true,
			element: <Home />,
		},
		{
			exact: true,
			path: 'apps',
			element: (
				<ApplicationList
					mainText="Ready-to-deploy apps for your next project"
					subText={
						<Typography variant="bodyLarge" fontWeight={600}>
							Solve real-world physical computing challenges immediately with
							ready-to-deploy applications that run directly on fleets of small
							devices. Deploy in just a few clicks and manage centrally over the
							air using balenaCloud.
						</Typography>
					}
					isOfClass="app"
				/>
			),
		},
		{
			exact: true,
			path: 'blocks',
			element: (
				<ApplicationList
					mainText="Drop-in solutions to your physical computing problems"
					subText={
						<Typography variant="bodyLarge" fontWeight={600}>
							Build with blocks to instantly satisfy common requirements and
							jump-start your product development.
						</Typography>
					}
					isOfClass="block"
				/>
			),
		},
		{
			exact: true,
			path: 'fleets-for-good',
			element: <FleetsForGood />,
		},
		{
			path: 'apps/:id/:name',
			element: <App />,
		},
		{
			path: 'apps/:id',
			element: <App />,
		},
		{
			path: 'blocks/:id/:name',
			element: <Block />,
		},
		{
			path: 'blocks/:id',
			element: <Block />,
		},
		{
			path: 'fleets-for-good/:id/:name',
			element: <FleetForGood />,
		},
		{
			path: 'fleets-for-good/:id',
			element: <FleetForGood />,
		},
		{
			path: 'terms',
			element: <Terms />,
		},
	];

	if (!isDevelopment) {
		return routes;
	}

	const devRoutes = [
		{
			path: 'theme',
			element: <ThemeOverrides />,
		},
	];

	return [...routes, ...devRoutes];
};

export const RootRouter = () => {
	const { state, dispatch } = useDeviceTypesContext();
	const [showCookieBanner, setShowCookieBanner] = React.useState(true);
	const { state: analyticsState, dispatch: dispatchAnalyticsState } =
		useAnalyticsContext();
	const location = useLocation();
	const [deviceTypesError, setDeviceTypesError] = React.useState<
		string | undefined
	>(undefined);
	React.useEffect(() => printConsoleGreeting(), []);

	React.useEffect(
		() => analyticsState.webTracker?.trackPageView?.(),
		[analyticsState.webTracker, location],
	);

	const { isLoading } = useQuery({
		queryKey: 'deviceTypes',
		queryFn: async () => {
			try {
				const dts = await getDeviceTypes();
				dispatch({ type: StoreActions.setDeviceTypes, payload: dts });
			} catch {
				setDeviceTypesError(
					'Failed to fetch device type information, compatibility information may be missing',
				);
				setTimeout(() => {
					setDeviceTypesError(undefined);
				}, 5000);
			}
		},
		suspense: !!state.deviceTypes?.length,
	});

	const handleCookiesBanner = React.useCallback(
		(selectedCookies?: Dictionary<Cookie>) => {
			if (!selectedCookies?.analytics?.value) {
				return;
			}
			dispatchAnalyticsState({
				type: AnalyticsStoreActions.setAnalyticsData,
				payload: {
					createAnalyticsClient: () =>
						createMarketingClient({
							endpoint: isProduction
								? 'data.balena-cloud.com'
								: 'data.balena-staging.com',
							projectName: 'balena-main',
							componentName: 'balena-hub',
							componentVersion: packageJson.version,
						}),
					trackerName: 'Marketplace',
				},
			});
		},
		[dispatchAnalyticsState],
	);

	React.useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [location.pathname]);

	if (isLoading) {
		return (
			<Backdrop
				open={true}
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		);
	}

	return (
		<>
			{deviceTypesError && (
				<Alert variant="filled" severity="error">
					{deviceTypesError}
				</Alert>
			)}
			<Routes>
				<Route path="/" element={<Outlet />}>
					{routes().map((routeProps, index) => (
						<Route key={index} {...routeProps} />
					))}
					<Route path="*" element={<Navigate to="/" replace />} />
				</Route>
			</Routes>
			<CookiesBanner
				show={showCookieBanner}
				productName="balenahub"
				cookies={cookies}
				removeCookies={['AMP_', '__analytics_']}
				onInit={handleCookiesBanner}
				onClose={(selectedCookies) => {
					handleCookiesBanner(selectedCookies);
					setShowCookieBanner(false);
				}}
			/>
			;
		</>
	);
};

import { Material } from '@balena/ui-shared-components';

const { Container, Skeleton, Stack } = Material;

export const DetailSkeleton = () => {
	return (
		<Stack spacing={2} p={[2, 2, 4]} flexWrap="wrap">
			<Stack
				spacing={[0, 0, 2]}
				direction="row"
				height={[400, 400, 300]}
				width="100%"
				alignItems="center"
				justifyContent="space-between"
				flexWrap="wrap"
			>
				<Stack spacing={[0, 2, 2]} direction="row" flexWrap="wrap">
					<Skeleton variant="rectangular" width={150} height={150} />
					<Stack
						spacing={2}
						direction="column"
						justifyContent="center"
						minWidth={340}
					>
						<Skeleton variant="text" component="h1" width="100%" />
						<Skeleton variant="text" component="span" width={200} />
						<Skeleton variant="text" component="h5" width="100%" />
						<Skeleton variant="text" component="h5" width="100%" />
					</Stack>
				</Stack>
				<Stack spacing={2} justifySelf="flex-end" flexWrap="wrap">
					<Skeleton
						variant="rectangular"
						component="span"
						width={170}
						height={40}
					/>
				</Stack>
			</Stack>
			<Stack
				width="100%"
				justifyContent="flex-end"
				direction="row"
				spacing={2}
				flexWrap="wrap"
			>
				<Skeleton variant="text" component="span" width={100} />
				<Skeleton variant="text" component="span" width={100} />
				<Skeleton variant="text" component="span" width={100} />
			</Stack>
			<Container maxWidth="xl" style={{ margin: '16px auto' }}>
				<Stack spacing={[0, 0, 3]} direction="row" flexWrap="wrap">
					<Skeleton
						animation="wave"
						variant="rectangular"
						height={700}
						sx={{
							width: { xs: '100%', sm: '100%', md: 300, lg: 300 },
							marginTop: { lg: '-30px', md: '-30px', sm: 0, xs: 0 },
						}}
					/>
					<Skeleton
						animation="wave"
						variant="rectangular"
						height={900}
						sx={{
							display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
							width: 'calc(100% - 320px)',
							mt: 4,
						}}
					/>
				</Stack>
			</Container>
		</Stack>
	);
};

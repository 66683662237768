import * as React from 'react';
import { BalenaSdk } from '../api-utils';

export interface DeviceTypeWithInstructions extends BalenaSdk.DeviceType {
	instructions?: string[] | BalenaSdk.DeviceTypeJson.DeviceTypeInstructions;
}

export enum StoreActions {
	setDeviceTypes = 'set_device_types',
}

type Action = {
	type: StoreActions.setDeviceTypes;
	payload: DeviceTypeWithInstructions[] | undefined;
};

type Dispatch = (action: Action) => void;

type DeviceTypesContext = {
	deviceTypes: DeviceTypeWithInstructions[] | undefined;
};

type DeviceTypesContextProviderProps = { children: React.ReactNode };
const DeviceTypesStateContext = React.createContext<
	{ state: DeviceTypesContext; dispatch: Dispatch } | undefined
>(undefined);

const initialContext: DeviceTypesContext = {
	deviceTypes: [],
};

const contextReducer = (
	state: DeviceTypesContext,
	{ type, payload }: Action,
) => {
	switch (type) {
		case StoreActions.setDeviceTypes: {
			return { ...state, deviceTypes: payload };
		}
		default: {
			throw new Error(`Unhandled action type: ${type}`);
		}
	}
};

export const DeviceTypesContextProvider = ({
	children,
}: DeviceTypesContextProviderProps) => {
	const [state, dispatch] = React.useReducer(contextReducer, initialContext);
	const value = { state, dispatch };
	return (
		<DeviceTypesStateContext.Provider value={value}>
			{children}
		</DeviceTypesStateContext.Provider>
	);
};

export const useDeviceTypesContext = () => {
	const context = React.useContext(DeviceTypesStateContext);
	if (context === undefined) {
		throw new Error(
			'useDeviceTypesContext must be used within a ContextProvider',
		);
	}
	return context;
};

import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';
import { BalenaSdk, getExpanded, getExpandedProp } from '../../api-utils';
import { getFleetForGood, getPublicDevices } from './queries';
import {
	getAppDescription,
	getAppLogo,
	getAppPostProvisioning,
	getAuthor,
	getWorksWith,
} from '../../utils/application';
import { EntityHeader } from '../../shared/EntityHeader';
import { Overview } from '../../shared/Overview';
import { DetailSkeleton } from '../../shared/DetailSkeleton';
import {
	IconsMaterial,
	Map,
	Markdown,
	Material,
	MUILinkWithTracking,
} from '@balena/ui-shared-components';
import { DownloadImage } from './DownloadImage';
import { useDeviceTypesContext } from '../../context/DeviceTypesContextProvider';
import BalenaWhiteIcon from '../../assets/balena_white_icon.svg';
import { updatePathIfRule } from '../../utils';

const { Box, Container, Grid, Typography } = Material;

const { OpenInNew } = IconsMaterial;

const API_KEY =
	process.env.REACT_APP_GOOGLE_API_KEY ||
	'AIzaSyDf0Nr2SdvVanZMKVp-8j0gaoyH0doaTvI';

export const FleetForGood: React.FC<{}> = () => {
	const { state } = useDeviceTypesContext();
	const { id, name } = useParams();

	const [
		{ data: fleetForGood, isLoading: isLoadingFleetForGood },
		{ data: devices },
	] = useQueries([
		{
			queryKey: ['fleetForGoodDetail', id],
			queryFn: async () => {
				try {
					const fleetForGood = await getFleetForGood(Number(id));
					updatePathIfRule(
						name !== fleetForGood?.app_name,
						`fleets-for-good/${id}/${fleetForGood?.app_name}`,
					);
					return fleetForGood;
				} catch (err) {
					console.error('Community Error: ', err);
				}
			},
			enabled: !!id && !!Number(id),
		},
		{
			queryKey: ['publicDevices', id],
			queryFn: async () => {
				try {
					return await getPublicDevices(Number(id));
				} catch (err) {
					console.error('Public Devices Error: ', err);
				}
			},
		},
	]);

	const memoizedFleetForGood = useMemo(() => {
		if (!fleetForGood) {
			return;
		}
		const release = getExpanded(fleetForGood?.should_be_running__release);
		return {
			id: fleetForGood.id,
			title: fleetForGood.app_name,
			slug: fleetForGood.slug,
			repoUrl: fleetForGood?.is_stored_at__repository_url,
			logo: getAppLogo(fleetForGood),
			authorElement: getAuthor(fleetForGood),
			author: getExpandedProp(fleetForGood.public_organization, 'name'),
			releaseId: release?.id,
			version: release?.raw_version ?? 'No release',
			supportedDeviceTypes: getWorksWith(fleetForGood, state.deviceTypes),
			defaultDeviceType: getExpandedProp(
				fleetForGood.is_for__device_type,
				'slug',
			),
			description: getAppDescription(fleetForGood),
			postProvisioning: getAppPostProvisioning(fleetForGood),
			notes: release?.note,
			price: 'Free',
			logoVariant: 'rounded' as Material.AvatarProps['variant'],
			isOfClass: 'fleet' as const,
		};
	}, [fleetForGood, state.deviceTypes]);

	if (isLoadingFleetForGood) {
		return <DetailSkeleton />;
	}

	if (!memoizedFleetForGood) {
		return null;
	}

	const externalLinks = [
		{
			label: 'View code',
			url: memoizedFleetForGood.repoUrl,
		},
		{
			label: 'Report issue',
			url: `${memoizedFleetForGood.repoUrl}/issues`,
		},
	];

	const overview = {
		title: 'Overview',
		informationList: [
			{
				label: 'Active devices',
				value: (
					<Typography
						variant="body1"
						width="87px"
						display="flex"
						justifyContent="center"
					>
						{devices?.length}
					</Typography>
				),
			},
		],
	};

	return (
		<>
			<EntityHeader
				entity={memoizedFleetForGood}
				bgcolor="customPurple.xlight"
				actionButton={
					fleetForGood &&
					!!memoizedFleetForGood.supportedDeviceTypes &&
					!!memoizedFleetForGood.defaultDeviceType && (
						<DownloadImage
							label="Join"
							startIcon={<img src={BalenaWhiteIcon} alt="balena icon" />}
							application={fleetForGood}
							releaseId={memoizedFleetForGood.releaseId}
							compatibleDeviceTypes={memoizedFleetForGood.supportedDeviceTypes}
							defaultDeviceType={memoizedFleetForGood.defaultDeviceType}
						/>
					)
				}
			>
				<Grid
					item
					xs={12}
					alignItems="flex-end"
					justifyContent="flex-end"
					display="flex"
					pt={5}
					pb={2}
				>
					{externalLinks.map((link, index) => (
						<MUILinkWithTracking
							key={index}
							href={link.url ?? undefined}
							target="_blank"
							variant="body1"
							display="flex"
							alignItems="center"
							mr={2}
						>
							{link.label}
							<OpenInNew sx={{ ml: 2 }} />
						</MUILinkWithTracking>
					))}
				</Grid>
			</EntityHeader>
			<Container maxWidth="xl">
				<Grid container>
					{API_KEY && devices && (
						<Box width="100%" height="400px">
							<Map
								apiKey={API_KEY}
								data={devices}
								dataMap={{
									lat: (item: BalenaSdk.PublicDevice) =>
										parseFloat(item.latitude),
									lng: (item: BalenaSdk.PublicDevice) =>
										parseFloat(item.longitude),
									id: (item: BalenaSdk.PublicDevice) =>
										item.latitude + item.longitude,
									title: () => `${memoizedFleetForGood.title} device`,
								}}
								style={{ height: '400px' }}
							/>
						</Box>
					)}
					<Grid
						item
						xs={12}
						sm={12}
						md={4}
						lg={3}
						mt={[0, 0, -4]}
						px={[3, 4]}
						py={2}
					>
						<Box
							maxWidth="250px"
							mt={API_KEY && devices ? [0, '-360px', '-460px'] : 0}
							position="relative"
						>
							<Overview overview={overview} />
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={8} lg={9} p={[2, 3, 4]}>
						<Container maxWidth="xl">
							<Typography variant="h5" fontWeight={600}>
								Description
							</Typography>
							{memoizedFleetForGood.postProvisioning ? (
								<Markdown children={memoizedFleetForGood.postProvisioning} />
							) : (
								<Box my={3}>
									<i>No description</i>
								</Box>
							)}
							<Box mt={3}>
								<Typography variant="h5" fontWeight={600}>
									Release notes
								</Typography>
								{memoizedFleetForGood.notes ? (
									<Markdown children={memoizedFleetForGood.notes} />
								) : (
									<Box my={3}>
										<i>No release notes</i>
									</Box>
								)}
							</Box>
						</Container>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

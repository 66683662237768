import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getExpanded, getExpandedProp } from '../../api-utils';
import { getBlock } from './queries';
import { useDeviceTypesContext } from '../../context/DeviceTypesContextProvider';
import {
	getAppDescription,
	getAppLogo,
	getAppPostProvisioning,
	getAuthor,
	getWorksWith,
} from '../../utils/application';
import { EntityHeader } from '../../shared/EntityHeader';
import {
	CollapsedList,
	Markdown,
	Code,
	MUILinkWithTracking,
	ButtonWithTracking,
	useAnalyticsContext,
	theme,
	Material,
	IconsMaterial,
} from '@balena/ui-shared-components';
import { Overview } from '../../shared/Overview';
import { DetailSkeleton } from '../../shared/DetailSkeleton';
import { updatePathIfRule } from '../../utils';

const { Box, Button, Container, Dialog, Grid, Typography } = Material;

const { OpenInNew, ContentCopy, Close } = IconsMaterial;

export const Block: React.FC<{}> = () => {
	const { id, name } = useParams();
	const { state } = useDeviceTypesContext();
	const { state: analyticsState } = useAnalyticsContext();

	const [showInstallationInstructions, setShowInstallationInstructions] =
		useState(false);

	const { data, isLoading } = useQuery({
		queryKey: ['blockDetail', id],
		queryFn: async () => {
			try {
				const block = await getBlock(Number(id));
				updatePathIfRule(
					name !== block?.app_name,
					`blocks/${id}/${block?.app_name}`,
				);
				return block;
			} catch (err) {
				console.error('App Error: ', err);
			}
		},
		enabled: !!id && !!Number(id),
	});

	const memoizedBlock = useMemo(() => {
		if (!data) {
			return;
		}
		const release = getExpanded(data?.should_be_running__release);
		const [releaseImage] = release?.release_image ?? [];
		const image = getExpanded(releaseImage?.image);
		const size = image?.image_size;
		return {
			id: data.id,
			title: data.app_name,
			slug: data.slug,
			repoUrl: data?.is_stored_at__repository_url,
			logo: getAppLogo(data),
			authorElement: getAuthor(data),
			author: getExpandedProp(data.public_organization, 'name'),
			version: release?.raw_version ?? 'No release',
			lastUpdate: release?.created_at
				? new Date(release.created_at).toLocaleDateString()
				: undefined,
			size: size ? `${Math.round(size / 1000000)}MB` : undefined,
			supportedDeviceTypes: getWorksWith(data, state.deviceTypes),
			description: getAppDescription(data),
			postProvisioning: getAppPostProvisioning(data),
			notes: release?.note,
			price: 'Free',
			logoVariant: 'rounded' as Material.AvatarProps['variant'],
			isOfClass: 'block' as const,
		};
	}, [data, state.deviceTypes]);

	const onDialogClose = (block: string) => {
		analyticsState.webTracker?.track?.('Block Instructions Closed', { block });
		setShowInstallationInstructions(false);
	};

	if (isLoading) {
		return <DetailSkeleton />;
	}

	if (!memoizedBlock) {
		return null;
	}

	const externalLinks = [
		{
			label: 'View code',
			url: memoizedBlock.repoUrl,
		},
		{
			label: 'Report issue',
			url: `${memoizedBlock.repoUrl}/issues`,
		},
	];

	const overview = {
		title: 'Block overview',
		informationList: [
			{
				label: 'Version',
				value: memoizedBlock.version,
			},
			{
				label: 'Size',
				value: memoizedBlock.size,
			},
			{
				label: 'Last Update',
				value: memoizedBlock.lastUpdate,
			},
			{
				label: 'Supported Devices',
				value: (
					<Box ml={2}>
						<CollapsedList list={memoizedBlock.supportedDeviceTypes} />
					</Box>
				),
			},
		],
	};

	return (
		<>
			<Dialog
				open={showInstallationInstructions}
				onClose={() => onDialogClose(memoizedBlock.slug)}
			>
				<Box p={3}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography variant="h5">Installation Instructions</Typography>
						<Button
							onClick={() => setShowInstallationInstructions(false)}
							color="customGrey"
							sx={{ minWidth: 0, minHeight: 0, px: 0 }}
						>
							<Close />
						</Button>
					</Box>
					<Typography my={2}>
						Copy and paste the following snippet in your{' '}
						<Code>docker-compose.yml</Code> file under the <Code>services</Code>{' '}
						section:
					</Typography>
					<Box
						display="flex"
						sx={(theme) => ({
							background: theme.palette.customGrey.xlight,
							borderRadius: 1,
						})}
						py={2}
						px={3}
						alignItems="center"
						justifyContent="space-between"
					>
						<Code>
							<Typography fontFamily="inherit">
								{memoizedBlock.title}:
							</Typography>
							<Typography ml={3} fontFamily="inherit">
								image: bh.cr/{memoizedBlock.slug}
							</Typography>
						</Code>
						<ButtonWithTracking
							eventName="Block Instructions Copied"
							eventProperties={{ block: memoizedBlock.slug }}
							onClick={() =>
								navigator.clipboard.writeText(`bh.cr/${memoizedBlock.slug}`)
							}
							sx={{ minWidth: 0, minHeight: 0, px: 0 }}
						>
							<ContentCopy
								sx={(theme) => ({ color: theme.palette.customGrey.dark })}
							/>
						</ButtonWithTracking>
					</Box>
					<Typography my={2}>
						Blocks might require additional configurations such as labels,{' '}
						<Box
							sx={(theme) => ({
								background: theme.palette.customGrey.xlight,
								borderRadius: 1,
							})}
							p={1}
							component="span"
						>
							<code
								style={{
									color: theme.palette.customGrey.dark,
								}}
							>
								network_mode
							</code>
						</Box>{' '}
						settings, volume configuration, etc. to function properly. Please
						check the block instructions or{' '}
						<MUILinkWithTracking
							href={memoizedBlock.repoUrl ?? ''}
							target="_blank"
							rel="noreferrer"
							data-underline="false"
						>
							repository
							<OpenInNew fontSize="inherit" />
						</MUILinkWithTracking>{' '}
						for more information.
					</Typography>
				</Box>
			</Dialog>
			<EntityHeader
				entity={memoizedBlock}
				bgcolor="customGreen.xlight"
				actionButton={{
					onClick: () => setShowInstallationInstructions(true),
					children: 'Use',
					eventName: 'Block instructions opened',
					eventProperties: {
						block: memoizedBlock.id,
					},
				}}
			>
				<Grid
					item
					xs={12}
					alignItems="flex-end"
					justifyContent="flex-end"
					display="flex"
					pt={5}
					pb={2}
				>
					{externalLinks.map((link, index) => (
						<MUILinkWithTracking
							key={index}
							href={link.url ?? undefined}
							target="_blank"
							underline="none"
							variant="body1"
							display="flex"
							alignItems="center"
							mr={2}
						>
							{link.label}
							<OpenInNew sx={{ ml: 2 }} />
						</MUILinkWithTracking>
					))}
				</Grid>
			</EntityHeader>
			<Container maxWidth="xl">
				<Grid container px={[3, 4]} py={2}>
					<Grid item xs={12} sm={12} md={4} lg={3} mt={[0, 0, -4]}>
						<Overview overview={overview} />
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={8}
						lg={9}
						p={[2, 3, 4]}
						sx={{ overflow: 'auto' }}
					>
						<Typography variant="h5" fontWeight={600}>
							Description
						</Typography>
						{memoizedBlock.postProvisioning ? (
							<Markdown children={memoizedBlock.postProvisioning} />
						) : (
							<Box my={3}>
								<i>No description</i>
							</Box>
						)}
						<Box mt={3}>
							<Typography variant="h5" fontWeight={600}>
								Release notes
							</Typography>
							{memoizedBlock.notes ? (
								<Markdown children={memoizedBlock.notes} />
							) : (
								<Box my={3}>
									<i>No release notes</i>
								</Box>
							)}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getExpanded, getExpandedProp } from '../../api-utils';
import { useDeviceTypesContext } from '../../context/DeviceTypesContextProvider';
import {
	getAppDescription,
	getAppLogo,
	getAppPostProvisioning,
	getAuthor,
	getWorksWith,
} from '../../utils/application';
import { EntityHeader } from '../../shared/EntityHeader';
import {
	CollapsedList,
	IconsMaterial,
	Markdown,
	Material,
	MUILinkWithTracking,
} from '@balena/ui-shared-components';
import { Overview } from '../../shared/Overview';
import { DetailSkeleton } from '../../shared/DetailSkeleton';
import { getApp } from './queries';
import BalenaCloudIcon from '../../assets/balena_cloud.svg';
import { updatePathIfRule } from '../../utils';

const { Box, Container, Grid, Typography } = Material;

const { OpenInNew } = IconsMaterial;

export const App: React.FC<{}> = () => {
	const { id, name } = useParams();
	const { state } = useDeviceTypesContext();

	const { data, isLoading } = useQuery({
		queryKey: ['appDetail', id],
		queryFn: async () => {
			try {
				const app = await getApp(Number(id));
				updatePathIfRule(name !== app?.app_name, `apps/${id}/${app?.app_name}`);
				return app;
			} catch (err) {
				console.error('App Error: ', err);
			}
		},
		enabled: !!id && !!Number(id),
	});

	const memoizedApp = useMemo(() => {
		if (!data) {
			return;
		}
		const release = getExpanded(data?.should_be_running__release);
		const [releaseImage] = release?.release_image ?? [];
		const image = getExpanded(releaseImage?.image);
		const size = image?.image_size;
		return {
			id: data.id,
			title: data.app_name,
			repoUrl: data?.is_stored_at__repository_url,
			logo: getAppLogo(data),
			authorElement: getAuthor(data),
			author: getExpandedProp(data.public_organization, 'name'),
			version: release?.raw_version ?? 'No release',
			lastUpdate: release?.created_at
				? new Date(release.created_at).toLocaleDateString()
				: undefined,
			size: size ? `${Math.round(size / 1000000)}MB` : undefined,
			supportedDeviceTypes: getWorksWith(data, state.deviceTypes),
			description: getAppDescription(data),
			postProvisioning: getAppPostProvisioning(data),
			notes: release?.note,
			price: 'Free',
			logoVariant: 'rounded' as Material.AvatarProps['variant'],
			isOfClass: 'app' as const,
		};
	}, [data, state.deviceTypes]);

	if (isLoading) {
		return <DetailSkeleton />;
	}

	if (!memoizedApp) {
		return null;
	}

	const externalLinks = [
		{
			label: 'View code',
			url: memoizedApp.repoUrl,
		},
		{
			label: 'Report issue',
			url: `${memoizedApp.repoUrl}/issues`,
		},
	];

	const overview = {
		title: 'App overview',
		informationList: [
			{
				label: 'Version',
				value: memoizedApp.version,
			},
			{
				label: 'Size',
				value: memoizedApp.size,
			},
			{
				label: 'Last Update',
				value: memoizedApp.lastUpdate,
			},
			{
				label: 'Supported Devices',
				value: (
					<Box ml={2}>
						<CollapsedList list={memoizedApp.supportedDeviceTypes} />
					</Box>
				),
			},
		],
	};

	return (
		<>
			<EntityHeader
				entity={memoizedApp}
				bgcolor="customBlue.xlight"
				actionButton={{
					onClick: () =>
						window.open(
							`https://dashboard.balena-cloud.com/deploy?repoUrl=${memoizedApp.repoUrl}`,
						),
					children: 'Deploy',
					eventName: 'Deploy App',
					eventProperties: {
						appId: memoizedApp.id,
						version: memoizedApp.version,
					},
					startIcon: (
						<img
							src={BalenaCloudIcon}
							alt="balenaCloud icon"
							style={{ height: '30px' }}
						/>
					),
				}}
			>
				<Grid
					item
					xs={12}
					alignItems="flex-end"
					justifyContent="flex-end"
					display="flex"
					pt={[2, 2, 5]}
					pb={2}
				>
					{externalLinks.map((link, index) => (
						<MUILinkWithTracking
							key={index}
							href={link.url ?? undefined}
							target="_blank"
							underline="none"
							variant="body1"
							display="flex"
							alignItems="center"
							mr={2}
							sx={(theme) => ({ color: theme.palette.text.secondary })}
						>
							{link.label}
							<OpenInNew sx={{ ml: 2 }} />
						</MUILinkWithTracking>
					))}
				</Grid>
			</EntityHeader>
			<Container maxWidth="xl">
				<Grid container px={[3, 4]} py={2}>
					<Grid item xs={12} sm={12} md={4} lg={3} mt={[0, 0, -4]}>
						<Overview overview={overview} />
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={8}
						lg={9}
						p={[2, 3, 4]}
						sx={{ overflow: 'auto' }}
					>
						<Typography variant="h5" fontWeight={600}>
							Description
						</Typography>
						{memoizedApp.postProvisioning ? (
							<Markdown children={memoizedApp.postProvisioning} />
						) : (
							<Box my={3}>
								<i>No description</i>
							</Box>
						)}
						<Box mt={3}>
							<Typography variant="h5" fontWeight={600}>
								Release notes
							</Typography>
							{memoizedApp.notes ? (
								<Markdown children={memoizedApp.notes} />
							) : (
								<Box my={3}>
									<i>No release notes</i>
								</Box>
							)}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

import balenaLogo from './assets/balena_logo.svg';
import {
	RouterLinkWithTracking,
	MUILinkWithTracking,
	useAnalyticsContext,
	theme,
	Material,
	IconsMaterial,
} from '@balena/ui-shared-components';
const { Box, Container, Typography, useMediaQuery, AppBar } = Material;
const { FacebookRounded, GitHub, Instagram, LinkedIn, Twitter, YouTube } =
	IconsMaterial;

const links = [
	{ icon: <GitHub />, href: 'https://github.com/balena-io' },
	{ icon: <Twitter />, href: 'https://twitter.com/balena_io' },
	{ icon: <FacebookRounded />, href: 'https://www.facebook.com/balenacloud/' },
	{ icon: <Instagram />, href: 'https://www.instagram.com/balena_io/' },
	{ icon: <LinkedIn />, href: 'https://www.linkedin.com/company/balenaio/' },
	{ icon: <YouTube />, href: 'https://www.youtube.com/balenaio' },
];

export const Footer: React.FC<{}> = () => {
	const matches = useMediaQuery(theme.breakpoints.up('md'));
	const { state: analyticsState } = useAnalyticsContext();

	return (
		<AppBar position="static" sx={{ bgcolor: 'customBlue.dark', py: 3, px: 5 }}>
			<Container disableGutters maxWidth="xl">
				<Box
					display="flex"
					flexDirection={matches ? 'row' : 'column'}
					justifyContent="space-between"
					alignItems="center"
					flex="1"
				>
					<MUILinkWithTracking
						href={analyticsState.trackBalenaNavigation(
							'https://www.balena.io/',
						)}
						target="_blank"
					>
						<Box
							component="img"
							src={balenaLogo}
							alt="balena logo"
							height="70px"
							width={['150px', '180px', '220px']}
						/>
					</MUILinkWithTracking>
					<RouterLinkWithTracking to="/terms" style={{ color: 'white' }}>
						Terms of use
					</RouterLinkWithTracking>
				</Box>
				<Box
					display="flex"
					flexDirection={matches ? 'row' : 'column-reverse'}
					width="100%"
					mt={['30px', '50px']}
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography
						variant="body2"
						mt={matches ? undefined : '24px'}
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						Copyright 2023 balena <span>All Rights Reserved</span>
					</Typography>
					<Box>
						{links.map((l, index) => (
							<MUILinkWithTracking
								href={l.href}
								target="_blank"
								sx={{ color: 'white' }}
								px={2}
								key={index}
							>
								{l.icon}
							</MUILinkWithTracking>
						))}
					</Box>
				</Box>
			</Container>
		</AppBar>
	);
};

import React, { useState } from 'react';
import {
	LimitedRowImages,
	LimitedRowImagesProps,
	Truncate,
	RouterLinkWithTracking,
	Material,
	IconsMaterial,
} from '@balena/ui-shared-components';
import { SectionText, SectionTextProps } from './SectionText';
import DefaultLogo from '../assets/default-app-logo.svg';
import { Theme } from '@emotion/react';

const {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Skeleton,
	Stack,
	Typography,
	Box,
} = Material;

const { ChevronRight } = IconsMaterial;

export const SectionCardSkeleton: React.FC<{}> = () => {
	return (
		<Stack spacing={1} width="100%">
			<Skeleton variant="rectangular" width="100%" height={140} />
			<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
			<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
			<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
		</Stack>
	);
};

export interface SectionCardProps {
	title: string | JSX.Element;
	author: string | JSX.Element;
	logo: string;
	link: string;
	imageList?: LimitedRowImagesProps['items'];
	footerTitle?: string;
	description?: string | JSX.Element;
	sx?: Material.SxProps;
	truncateDescription?: boolean;
}

export const SectionCard: React.FC<SectionCardProps> = ({
	title,
	author,
	logo,
	footerTitle,
	imageList,
	description,
	sx,
	truncateDescription,
}) => {
	const [avatar, setAvatar] = useState<string | undefined>(undefined);

	const isWithFooter = footerTitle || imageList;
	return (
		<Card sx={{ height: '321.5px', ...sx }}>
			<CardHeader
				sx={{ height: isWithFooter ? '30%' : '40%' }}
				avatar={
					<Avatar
						variant="rounded"
						src={avatar ?? logo}
						arial-label={title}
						sx={{ width: '100%', height: '100%' }}
						imgProps={{ onError: () => setAvatar(DefaultLogo) }}
					/>
				}
				title={
					<Truncate lineCamp={1} tooltip>
						<Typography variant="h5">{title}</Typography>
					</Truncate>
				}
				subheader={author}
			/>
			<CardContent sx={{ height: '40%' }}>
				<Truncate lineCamp={!truncateDescription ? 5 : isWithFooter ? 4 : 2}>
					<Typography variant="body1">{description}</Typography>
				</Truncate>
			</CardContent>
			{isWithFooter && (
				<Box height="30%">
					<Divider
						sx={{
							borderStyle: 'dashed',
						}}
					/>
					<CardActions
						sx={{
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						{footerTitle && (
							<Typography mb={1} variant="smallText">
								{footerTitle}
							</Typography>
						)}
						{imageList && <LimitedRowImages items={imageList} />}
					</CardActions>
				</Box>
			)}
		</Card>
	);
};

interface RowSectionProps {
	entries: SectionCardProps[];
	title?: string;
	redirect?: { href: string; title: string };
	orderedRedirect?: { href: string; title: string; state?: any };
	truncateCardDescriptions?: boolean;
	cardSx?: Material.SxProps<Theme>;
}

const RowSection: React.FC<
	RowSectionProps & {
		itemsPerPage: number;
		customGrid?: Material.RegularBreakpoints;
	}
> = ({
	title,
	entries,
	orderedRedirect,
	itemsPerPage,
	customGrid,
	truncateCardDescriptions,
	cardSx,
}) => {
	const itemList = !entries.length
		? Array.from(Array(itemsPerPage).keys())
		: entries;
	return (
		<Box>
			{(title || orderedRedirect) && (
				<Box
					display="flex"
					justifyContent="space-between"
					mb={2}
					position="relative"
				>
					{title && (
						<Typography variant="h5" fontWeight="600">
							{title}
						</Typography>
					)}
					{orderedRedirect && (
						<RouterLinkWithTracking
							to={orderedRedirect.href}
							state={orderedRedirect.state}
						>
							<Typography
								variant="link"
								display="flex"
								alignItems="center"
								lineHeight={3}
							>
								{orderedRedirect.title} <ChevronRight />
							</Typography>
						</RouterLinkWithTracking>
					)}
				</Box>
			)}
			<Grid container columnSpacing={4}>
				{itemList.map((entry, index: number) => (
					<Grid
						item
						height={
							typeof entry === 'object' &&
							!entry.footerTitle &&
							!entry.imageList
								? 200
								: 340
						}
						lg={4}
						md={6}
						sm={6}
						xs={12}
						{...customGrid}
						key={index}
						mt={3}
						display="flex"
						justifyContent="center"
					>
						{typeof entry === 'number' ? (
							<SectionCardSkeleton />
						) : (
							<RouterLinkWithTracking to={entry.link} style={{ width: '100%' }}>
								<SectionCard
									{...entry}
									truncateDescription={truncateCardDescriptions}
									sx={cardSx}
								/>
							</RouterLinkWithTracking>
						)}
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

interface SectionResourceProps {
	sections: Array<
		RowSectionProps & {
			sectionText?: SectionTextProps;
		}
	>;
	itemsPerPage?: number;
	customGrid?: Material.RegularBreakpoints;
	eventOrigin?: string;
	truncateCardDescriptions?: boolean;
	cardSx?: Material.SxProps<Theme>;
}

export const SectionResource: React.FC<SectionResourceProps> = ({
	sections,
	customGrid,
	eventOrigin,
	itemsPerPage = 9,
	truncateCardDescriptions,
	cardSx,
}) => {
	return (
		<Box width="100%">
			{sections.map((section, index) =>
				section.sectionText ? (
					<Grid
						container
						key={index}
						rowSpacing={section.orderedRedirect ? 4 : 3}
					>
						{section.sectionText && (
							<Grid
								item
								xs={12}
								sm={12}
								md={section.orderedRedirect ? 3 : 12}
								lg={section.orderedRedirect ? 3 : 12}
								mt={[2, 4, 0]}
								mb={section.orderedRedirect ? [4, 4, 0] : 2}
								textAlign={section.orderedRedirect ? 'inherit' : 'center'}
							>
								<Box
									minHeight={
										section.orderedRedirect
											? ['230px', '200px', '320px', '300px', '250px']
											: 0
									}
									pr={section.orderedRedirect ? [0, 0, 1, 4] : 0}
									mt="-10px"
									mb={section.orderedRedirect ? 0 : 3}
								>
									<SectionText {...section.sectionText} titleVariant="h3" />
								</Box>
								{section.redirect && (
									<RouterLinkWithTracking
										to={section.redirect.href}
										eventProperties={{
											origin: eventOrigin ?? 'section resource',
										}}
									>
										<Button variant="outlined" color="secondary">
											{section.redirect.title}
										</Button>
									</RouterLinkWithTracking>
								)}
							</Grid>
						)}
						<Grid
							item
							my={[2, 2, 0]}
							xs={12}
							sm={12}
							md={section.sectionText ? 9 : 12}
							lg={section.sectionText ? 9 : 12}
							mx="auto"
						>
							<RowSection
								truncateCardDescriptions={truncateCardDescriptions}
								key={index}
								itemsPerPage={itemsPerPage}
								cardSx={cardSx}
								{...section}
							/>
						</Grid>
					</Grid>
				) : (
					<RowSection
						truncateCardDescriptions={truncateCardDescriptions}
						key={index}
						customGrid={customGrid}
						itemsPerPage={itemsPerPage}
						cardSx={cardSx}
						{...section}
					/>
				),
			)}
		</Box>
	);
};

import { Material } from '@balena/ui-shared-components';

const { Box, FormControl, InputLabel, MenuItem, Select } = Material;
interface OrderByProps {
	orderBy: string;
	options: Array<{ value: string; label: string }>;
	onChange: ((data: string) => void) | undefined;
}

export const OrderBy: React.FC<OrderByProps> = ({
	orderBy,
	options,
	onChange,
}) => {
	return (
		<Box display="flex" justifyContent="flex-end">
			<FormControl sx={{ width: '220px' }}>
				<InputLabel id="order-by-label">Sort by</InputLabel>
				<Select
					label="Sort by"
					labelId="order-by-label"
					size="small"
					value={orderBy}
					onChange={(e) => onChange?.(e.target.value)}
				>
					{options.map((option, index) => (
						<MenuItem key={index} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

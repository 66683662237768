import { BalenaSdk, sdk } from '../../api-utils';

export const getApp = async (id: number) => {
	const options: BalenaSdk.PineOptionsWithSelect<BalenaSdk.Application> = {
		$select: ['id', 'app_name', 'is_of__class', 'is_stored_at__repository_url'],
		$expand: {
			is_for__device_type: {
				$select: ['slug'],
			},
			public_organization: {
				$select: ['name'],
			},
			should_be_running__release: {
				$select: ['contract', 'raw_version', 'created_at', 'note'],
				$expand: {
					release_image: {
						$select: 'id',
						$expand: {
							image: {
								$select: 'image_size',
							},
						},
					},
				},
				$filter: {
					status: 'success',
					is_final: true,
				},
			},
		},
	};
	return await sdk.models.application.get(id, options);
};

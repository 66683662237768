import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from './Breadcrumbs';
import { Material } from '@balena/ui-shared-components';

const { Box, Container, Typography } = Material;
export interface SectionTextProps {
	mainText: string | JSX.Element;
	subText: string | JSX.Element;
	sx?: Material.SxProps<Material.Theme>;
	titleVariant?: Material.TypographyProps['variant'];
	showBreadcrumbs?: boolean;
}

export const SectionText: React.FC<SectionTextProps> = ({
	mainText,
	subText,
	sx,
	titleVariant = 'h1',
	showBreadcrumbs,
}) => {
	const { pathname } = useLocation();
	const sectionName = pathname.split('/')[1];

	const breadcrumbs = [
		{ label: 'Home', link: '/' },
		{
			label: `${sectionName.charAt(0).toUpperCase()}${sectionName.substring(1)}`
				.split('-')
				.join(' '),
		},
	];

	return (
		<Box sx={{ ...sx }}>
			{showBreadcrumbs && (
				<Breadcrumbs breadcrumbs={breadcrumbs} sx={{ ml: 5 }} />
			)}
			<Container
				maxWidth="md"
				sx={{ py: showBreadcrumbs ? { xs: 4, md: 5, lg: 5 } : 0 }}
				disableGutters
			>
				{typeof mainText === 'string' ? (
					<Typography variant={titleVariant} mb="25px">
						{mainText}
					</Typography>
				) : (
					mainText
				)}
				{typeof subText === 'string' ? (
					<Typography variant="body1" color="secondary">
						{subText}
					</Typography>
				) : (
					subText
				)}
			</Container>
		</Box>
	);
};

import { BrowserRouter } from 'react-router-dom';
import { Footer } from './Footer';
import { Header } from './Header';
import { RootRouter } from './RootRouter';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DeviceTypesContextProvider } from './context/DeviceTypesContextProvider';
import {
	AnalyticsContextProvider,
	Material,
} from '@balena/ui-shared-components';
const { Box, useTheme } = Material;

export const App: React.FC<{}> = () => {
	const theme = useTheme();
	const queryClient = new QueryClient();

	return (
		<DeviceTypesContextProvider>
			<AnalyticsContextProvider>
				<Box display="flex" height="100vh" width="100%" flexDirection="column">
					<QueryClientProvider client={queryClient}>
						<BrowserRouter>
							<Box flex="0 0 auto" sx={{ ...theme.mixins.toolbar }}>
								<Header />
							</Box>
							<Box flex="1 0 auto">
								<RootRouter />
							</Box>
							<Box flex="0 0 auto">
								<Footer />
							</Box>
						</BrowserRouter>
					</QueryClientProvider>
				</Box>
			</AnalyticsContextProvider>
		</DeviceTypesContextProvider>
	);
};

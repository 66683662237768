import { BalenaSdk, sdk } from '../../api-utils';

export const getApplicationListTotalItems = async (
	filter?: BalenaSdk.PineFilter<BalenaSdk.Application>,
) => {
	const options: BalenaSdk.PineOptionsWithSelect<BalenaSdk.Application> = {
		$orderby: 'created_at',
		$select: 'id',
		$filter: {
			is_host: false,
			is_discoverable: true,
		},
	};

	if (filter != null) {
		options.$filter = { ...options.$filter, ...filter };
	}

	const applicationList = await sdk.models.application.getAll(options);

	return applicationList.length;
};

export const getApplicationList = async (
	filter?: BalenaSdk.PineFilter<BalenaSdk.Application>,
	top?: number,
	skip?: number,
	orderBy?: string,
) => {
	const options: BalenaSdk.PineOptionsWithSelect<BalenaSdk.Application> = {
		$orderby: orderBy || 'app_name',
		$select: ['id', 'app_name', 'is_of__class'],
		$expand: {
			is_for__device_type: {
				$select: ['slug'],
			},
			public_organization: {
				$select: ['name'],
			},
			should_be_running__release: {
				$select: ['contract', 'raw_version'],
				$filter: {
					status: 'success',
					is_final: true,
				},
			},
		},
		$filter: {
			is_host: false,
			is_discoverable: true,
		},
	};
	if (top != null) {
		options.$top = top;
	}

	if (top != null) {
		options.$skip = skip;
	}

	if (filter != null) {
		options.$filter = { ...options.$filter, ...filter };
	}

	return await sdk.models.application.getAll(options);
};

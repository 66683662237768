import type { Dictionary } from 'balena-sdk/typings/utils';
import { BalenaSdk, sdk } from '../../api-utils';
import template from 'lodash/template';
import semver from 'semver';
import memoizee from 'memoizee';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

export const FALLBACK_LOGO_UNKNOWN_DEVICE =
	'https://dashboard.balena-cloud.com/img/unknown-device.svg';

export const OS_VARIANT_FULL_DISPLAY_TEXT_MAP: Dictionary<string> = {
	dev: 'Development',
	prod: 'Production',
};

const getSupportedHostApps = (applicationId: number, deviceTypes: string[]) => {
	return sdk.pine.get<BalenaSdk.ApplicationHostedOnApplication>({
		resource: 'application__can_use__application_as_host',
		options: {
			$select: ['can_use__application_as_host'],
			$filter: {
				application: applicationId,
				can_use__application_as_host: {
					is_for__device_type: {
						$any: {
							$alias: 'dt',
							$expr: {
								dt: {
									slug: { $in: deviceTypes },
								},
							},
						},
					},
				},
			},
			$expand: {
				can_use__application_as_host: {
					$select: ['id', 'app_name'],
					$expand: {
						application_tag: {
							$select: ['id', 'tag_key', 'value'],
						},
						is_for__device_type: {
							$select: ['slug'],
						},
					},
				},
			},
		},
	});
};

export const getUniqueOsTypes = (
	osVersions: BalenaSdk.OsVersionsByDeviceType,
	deviceTypeSlug: string | undefined,
) => {
	if (
		isEmpty(osVersions) ||
		!deviceTypeSlug ||
		isEmpty(osVersions[deviceTypeSlug])
	) {
		return [];
	}

	return uniq(osVersions[deviceTypeSlug]).map((os) => os.osType);
};

export const stripVersionBuild = (version: string) =>
	version.replace(/(\.dev|\.prod)/, '');

// Use lodash templates to simulate moustache templating
export const interpolateMustache = (
	data: { [key: string]: string },
	tpl: string,
) => template(tpl, { interpolate: /{{([\s\S]+?)}}/g })(data);

export const getOsTypeName = (osTypeSlug: string) => {
	switch (osTypeSlug) {
		case sdk.models.os.OsTypes.DEFAULT:
			return 'balenaOS';
		case sdk.models.os.OsTypes.ESR:
			return 'balenaOS ESR';
		default:
			return 'unknown';
	}
};

export const getOsVariantDisplayText = (variant: string): string => {
	return OS_VARIANT_FULL_DISPLAY_TEXT_MAP[variant] || variant;
};

export const makeDockerTag = (value: string = '') =>
	value.replace(/[^a-z0-9A-Z_.-]/g, '_');

// Given an OS version, returns the docker image for the current deployment
export const getDockerArtifact = (slug: string, version: string) => {
	// TODO: deployment === 'STAGING' ? 'resin/resinos-staging' : 'resin/resinos'
	const baseImage = 'resin/resinos';
	const rawTag = `${version}-${slug}`;

	return `${baseImage}:${makeDockerTag(rawTag)}`;
};

const RELEASE_POLICY_TAG_NAME = 'release-policy';
const ESR_NEXT_TAG_NAME = 'esr-next';
const ESR_CURRENT_TAG_NAME = 'esr-current';
const ESR_SUNSET_TAG_NAME = 'esr-sunset';

const memoizedGetSupportedHostApps = memoizee(getSupportedHostApps, {
	maxAge: 10 * 60 * 1000,
	primitive: true,
	promise: true,
});

const getTagValue = (tags: BalenaSdk.ResourceTagBase[], tagKey: string) => {
	return tags.find((tag) => tag.tag_key === tagKey)?.value;
};

const getOsAppTags = (applicationTag: BalenaSdk.ApplicationTag[]) => {
	return {
		osType:
			getTagValue(applicationTag, RELEASE_POLICY_TAG_NAME) ??
			sdk.models.os.OsTypes.DEFAULT,
		nextLineVersionRange: getTagValue(applicationTag, ESR_NEXT_TAG_NAME) ?? '',
		currentLineVersionRange:
			getTagValue(applicationTag, ESR_CURRENT_TAG_NAME) ?? '',
		sunsetLineVersionRange:
			getTagValue(applicationTag, ESR_SUNSET_TAG_NAME) ?? '',
	};
};

export const getSupportedOsTypes = (
	applicationId: number,
	deviceTypes: string[],
): Promise<string[]> => {
	return memoizedGetSupportedHostApps(applicationId, deviceTypes)
		.then((resp) => {
			return resp.reduce((osTypes: Set<string>, hostApps) => {
				const hostApp = (
					hostApps.can_use__application_as_host as BalenaSdk.Application[]
				)[0];
				if (!hostApp) {
					return osTypes;
				}

				const appTags = getOsAppTags(hostApp.application_tag ?? []);
				if (appTags.osType) {
					osTypes.add(appTags.osType);
				}

				return osTypes;
			}, new Set<string>());
		})
		.then((osTypesSet) => Array.from(osTypesSet))
		.catch((e) => {
			console.error('Unable to retrieve OS version types', e);
			return [];
		});
};

const filterVersionsForAppType = (
	versions: BalenaSdk.OsVersion[],
	appType?: BalenaSdk.ApplicationType,
	osType?: BalenaSdk.OsTypes,
) => {
	// If app type is passed, remove any os versions that don't apply to that app type.
	const osVersionRange = appType?.needs__os_version_range;
	return versions.filter((version) => {
		return (
			(osType == null || version.osType === osType) &&
			(osVersionRange == null ||
				semver.satisfies(version.strippedVersion, osVersionRange))
		);
	});
};

export const filterOsVersionsForOsTypes = (
	osVersions: BalenaSdk.OsVersionsByDeviceType,
	osTypes: string[],
) => {
	return Object.keys(osVersions).reduce(
		(filteredOsVersions: BalenaSdk.OsVersionsByDeviceType, deviceTypeKey) => {
			filteredOsVersions[deviceTypeKey] = osVersions[deviceTypeKey].filter(
				(osVersion) => osTypes.includes(osVersion.osType),
			);
			return filteredOsVersions;
		},
		{},
	);
};

export const getSupportedOsVersions = (
	applicationId: number,
	deviceTypes: string[],
	appType?: BalenaSdk.ApplicationType,
) => {
	return Promise.all([
		sdk.models.os.getAvailableOsVersions(deviceTypes).then((res) => {
			Object.keys(res).forEach((deviceType) => {
				res[deviceType] = filterVersionsForAppType(
					res[deviceType],
					appType,
					sdk.models.os.OsTypes.DEFAULT,
				);
			});
			return res;
		}),
		getSupportedOsTypes(applicationId, deviceTypes),
	]).then(([osVersions, osTypes]) => {
		return filterOsVersionsForOsTypes(osVersions, osTypes);
	});
};

export const formatSize = (bytes: number, base = 1000) => {
	if (typeof bytes !== 'number' || bytes < 0) {
		return null;
	}
	const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
	let order = Math.floor(Math.log(bytes) / Math.log(base));
	if (order >= units.length) {
		order = units.length - 1;
	}
	const size = bytes / Math.pow(base, order);
	let result;
	if (order < 0) {
		result = bytes;
		order = 0;
	} else if (order >= 3 && size !== Math.floor(size)) {
		result = size.toFixed(1);
	} else {
		result = size.toFixed();
	}
	return `${result} ${units[order]}`;
};

export const getDownloadSize = (
	slug: string,
	version?: string,
): Promise<string> => {
	return sdk.models.os
		.getDownloadSize(slug, version)
		.then((bytes) => {
			// that probably means that there is no deploy artifact
			// so don't return a result that doesn't make sense
			if (bytes <= 0) {
				return '';
			}
			return formatSize(bytes) || '';
		})
		.catch(() => {
			// No need to report exception, as it doesn't degrade functionality if it fails.
			return '';
		});
};

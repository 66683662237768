import { useQueries } from 'react-query';
import { useDeviceTypesContext } from '../../context/DeviceTypesContextProvider';
import {
	SectionCard,
	SectionCardProps,
	SectionCardSkeleton,
	SectionResource,
} from '../../shared/SectionResource';
import { SectionText } from '../../shared/SectionText';
import {
	APPLICATION_BACKGROUND,
	appToCardProps,
} from '../../utils/application';
import TitleBackground from '../../assets/balenahub_pattern_light.svg';
import { getFleet, getFleetList, getFleetListTotalItems } from './queries';
import { useState } from 'react';
import {
	RouterLinkWithTracking,
	CustomHelmet,
	Material,
} from '@balena/ui-shared-components';
import logo from '../../assets/balenahub_logo_small.png';

const { Box, Container, Grid, Pagination, Typography } = Material;

const ITEMS_PER_PAGE = 12;
const isOfClass = 'fleet';
const mainText = 'Help the IoT community make a difference in the world';
const subText = (
	<Typography variant="bodyLarge" fontWeight={600}>
		Fleets for Good is our way of giving our platform and products for free so
		we can join together and make an impact.
	</Typography>
);

const queries = {
	helpPowerResearchFleet: 1918419,
	foldForCovidArm: 1635296,
	foldForCovidAmd: 1635297,
	ribbitOne: 1837489,
	ribbitTwo: 1994710,
	ribbitThree: 1994686,
	grauninhaOne: 1991158,
	grauninhaTwo: 1907482,
	grauninhaThree: 1994381,
	knowYourEnvironment: 1866434,
};

export const FleetsForGood: React.FC<{}> = () => {
	const { state } = useDeviceTypesContext();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [
		{ data: helpPowerResearchFleet },
		{ data: foldForCovidArm },
		{ data: foldForCovidAmd },
		{ data: ribbitOne },
		{ data: ribbitTwo },
		{ data: ribbitThree },
		{ data: grauninhaOne },
		{ data: grauninhaTwo },
		{ data: grauninhaThree },
		{ data: knowYourEnvironment },
	] = useQueries([
		...Object.entries(queries).map(([queryKey, queryId]) => ({
			queryKey: [queryKey],
			queryFn: async () => {
				try {
					return await getFleet(queryId);
				} catch (err) {
					console.error('helpPowerResearchFleet Error: ', err);
				}
			},
		})),
	]);
	const [{ data: totalItems }, { data: fleetsForGood }] = useQueries([
		{
			queryKey: ['FleetForGoodItems'],
			queryFn: async () => {
				try {
					return await getFleetListTotalItems({
						is_of__class: isOfClass,
						$not: { id: { $in: Object.values(queries) } },
					});
				} catch (err) {
					console.error('getFleetForGoodItems Error: ', err);
				}
			},
		},
		{
			queryKey: ['FleetForGood', currentPage],
			queryFn: async () => {
				try {
					return await getFleetList(
						{
							is_of__class: isOfClass,
							$not: { id: { $in: Object.values(queries) } },
						},
						ITEMS_PER_PAGE,
						(currentPage - 1) * ITEMS_PER_PAGE,
					);
				} catch (err) {
					console.error('getFleetForGood Error: ', err);
				}
			},
		},
	]);

	const helpPowerResearchFleetProps =
		helpPowerResearchFleet &&
		appToCardProps(helpPowerResearchFleet, state.deviceTypes, false);
	const foldForCovidArmProps =
		foldForCovidArm &&
		appToCardProps(foldForCovidArm, state.deviceTypes, false);
	const foldForCovidAmdProps =
		foldForCovidAmd &&
		appToCardProps(foldForCovidAmd, state.deviceTypes, false);
	const ribbitOneProps =
		ribbitOne && appToCardProps(ribbitOne, state.deviceTypes, false);
	const ribbitTwoProps =
		ribbitTwo && appToCardProps(ribbitTwo, state.deviceTypes, false);
	const ribbitThreeProps =
		ribbitThree && appToCardProps(ribbitThree, state.deviceTypes, false);
	const grauninhaOneProps =
		grauninhaOne && appToCardProps(grauninhaOne, state.deviceTypes, false);
	const grauninhaTwoProps =
		grauninhaTwo && appToCardProps(grauninhaTwo, state.deviceTypes, false);
	const grauninhaThreeProps =
		grauninhaThree && appToCardProps(grauninhaThree, state.deviceTypes, false);
	const knowYourEnvironmentProps =
		knowYourEnvironment &&
		appToCardProps(knowYourEnvironment, state.deviceTypes, false);

	const sections = [
		{
			entries:
				fleetsForGood?.map((fleet) =>
					appToCardProps(fleet, state.deviceTypes, false),
				) ?? [],
		},
	];

	const meta = {
		title: 'Fleets for good | balena',
		description: 'List of Fleets for good on balenaHub.',
		properties: {
			'og:title': 'Fleets for good | balena',
			'og:description': `List of Fleets for good on balenaHub.`,
			'og:image': logo,
			'og:url': window.location.href,
			'twitter:title': `Fleets for good | balena`,
			'twitter:description': `List of Fleets for good on balenaHub.`,
			'twitter:image': logo,
		},
	};

	return (
		<>
			<CustomHelmet {...meta} />
			<Grid container>
				<Grid item xs={12}>
					<SectionText
						mainText={mainText}
						subText={subText}
						sx={{
							backgroundColor: APPLICATION_BACKGROUND[isOfClass],
							backgroundImage: `url(${TitleBackground})`,
							color: 'text.main',
							backgroundSize: '65%',
							backgroundPosition: 'center',
							paddingY: { xs: 4, md: 1 },
							textAlign: 'center',
						}}
						showBreadcrumbs
					/>
				</Grid>
				<Grid item xs={12} py={[4, 5]}>
					<Container maxWidth="md">
						<Typography variant="body1">
							Whether you want to help others measure the air quality in their
							homes or you’re a collective working to further research for a
							global initiative, we want to help your cause by enabling you to
							use our tools free of charge.
						</Typography>

						<Typography variant="body1" mt={3}>
							Fleets for Good lets members join and contribute their devices (no
							balenaCloud account required for users just wanting to add a
							device). The fleet owner then has the ability to access, manage
							and use the device to further the cause, and has the
							responsibility of maintaining the fleet as an open, non-commercial
							project. Fleets for good have no device limit, encouraging owners
							to try and get more users to support and grow their projects to
							achieve something good.
						</Typography>
					</Container>
				</Grid>
				<Grid item xs={12}>
					<SingleItemRow
						sectionCardProps={helpPowerResearchFleetProps}
						title="Help power research"
						description="Enroll your edge / IoT device into the CHI@Edge testbed for computer science research. Note: you must be associated with a Chameleon allocation to join the testbed as configured."
						sx={{ mb: 5 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<MultipleItemsRow
						title="Fold for Covid"
						description="Fold for Covid - Help fight the COVID-19 pandemic with your old laptop, Raspberry Pi, or other spare computer"
						sectionCardsProps={[foldForCovidArmProps, foldForCovidAmdProps]}
					/>
				</Grid>
				<Grid item xs={12}>
					<MultipleItemsRow
						title="Join a global CO2 sensor network"
						description="The sensor for the world's largest crowdsourced network of open-source, low-cost, CO2 Gas Detection Sensors."
						sectionCardsProps={[
							ribbitOneProps,
							ribbitTwoProps,
							ribbitThreeProps,
						]}
						sx={{
							background:
								'linear-gradient(to bottom, #F2EDFF 0, #F2EDFF 550px, transparent 550px, transparent 100%)',
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<MultipleItemsRow
						title="Grauninha"
						description="Grauna community server, part of the Grauna project that also hasthe site collector Grauna Memória"
						sectionCardsProps={[
							grauninhaOneProps,
							grauninhaTwoProps,
							grauninhaThreeProps,
						]}
					/>
				</Grid>
				<Grid item xs={12}>
					<SingleItemRow
						sectionCardProps={knowYourEnvironmentProps}
						title="Know your environment"
						description="balenAir is dedicated to making environmental information visibly more accessible to consumers so they can make actionable decisions to improve their health and wellbeing."
						sx={{ mt: 5 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Container
						maxWidth="xl"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							py: { xs: 3, md: 5 },
						}}
					>
						<Typography variant="h3" my={[3, 3, 5]}>
							More
						</Typography>
						<SectionResource
							sections={sections}
							itemsPerPage={ITEMS_PER_PAGE}
							customGrid={{
								md: 4,
								lg: 3,
							}}
							truncateCardDescriptions
							cardSx={{ width: '321px', margin: '0 auto' }}
						/>
						{totalItems && totalItems > ITEMS_PER_PAGE && (
							<Pagination
								count={Math.ceil(totalItems / ITEMS_PER_PAGE)}
								onChange={(_event, page) => setCurrentPage(page)}
								shape="rounded"
								sx={{ width: 'fit-content', my: 5, mx: 'auto' }}
							/>
						)}
					</Container>
				</Grid>
			</Grid>
		</>
	);
};

const SingleItemRow: React.FC<{
	sectionCardProps: SectionCardProps | undefined;
	title: string;
	description: string;
	sx?: Material.SxProps<Material.Theme>;
}> = ({ sectionCardProps, title, description, sx }) => {
	if (!sectionCardProps) {
		return null;
	}

	return (
		<Box
			display="flex"
			flexDirection="row"
			py={2}
			sx={{
				backgroundColor: 'customPurple.main',
				color: 'white',
				...sx,
			}}
		>
			<Grid container>
				<Grid item xs={12} md={6} lg={6}>
					<Box
						display="flex"
						flexDirection="column"
						flex="1"
						my={-4}
						alignItems={['center', 'center', 'flex-end']}
						pr={[0, 0, 4]}
					>
						{sectionCardProps ? (
							<RouterLinkWithTracking to={sectionCardProps.link}>
								<SectionCard
									{...sectionCardProps}
									sx={{
										width: { xs: '321px', sm: '350px', md: '321px' },
										p: 3,
									}}
									truncateDescription={true}
								/>
							</RouterLinkWithTracking>
						) : (
							<SectionCardSkeleton />
						)}
					</Box>
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<Box
						display="flex"
						flexDirection="column"
						flex="1"
						px={4}
						py={[3, 2, 0]}
						justifyContent="center"
						mt={[5, 5, 0]}
						width={['auto', 'auto', '400px']}
					>
						<Typography variant="h3" mb={3}>
							{title}
						</Typography>
						<Typography variant="bodyLarge">{description}</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

const MultipleItemsRow: React.FC<{
	sectionCardsProps: Array<SectionCardProps | undefined>;
	title: string;
	description: string;
	sx?: Material.SxProps<Material.Theme>;
}> = ({ sectionCardsProps, title, description, sx }) => {
	return (
		<Box
			display="flex"
			flexDirection="row"
			py={[2, 3, 5, 5]}
			mx="auto"
			sx={{ ...sx }}
		>
			<Box display="flex" flexDirection="column" flex="1">
				<Typography variant="h3" mb={3} textAlign="center">
					{title}
				</Typography>
				<Typography variant="bodyLarge" textAlign="center">
					{description}
				</Typography>
				<Container maxWidth="lg">
					<Grid
						container
						spacing={3}
						py={[4, 4, 0]}
						direction={['column', 'column', 'row']}
						flexWrap="nowrap"
						alignItems="center"
						mt={4}
					>
						{sectionCardsProps.map((sectionCardProps, index) => (
							<Grid
								item
								xs={12}
								sm={12}
								md={12 / sectionCardsProps.length}
								display="flex"
								direction="column"
								alignItems={
									sectionCardsProps.length === 2
										? index === 0
											? 'flex-end'
											: 'flex-start'
										: 'center'
								}
							>
								{sectionCardProps ? (
									<RouterLinkWithTracking to={sectionCardProps.link}>
										<SectionCard
											{...sectionCardProps}
											sx={{
												width: {
													xs: '321px',
													sm: '350px',
													md: '280px',
													lg: '350px',
												},
												p: 3,
											}}
										/>
									</RouterLinkWithTracking>
								) : (
									<SectionCardSkeleton />
								)}
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		</Box>
	);
};

import invert from 'lodash/invert';
import has from 'lodash/has';
import { interpolateMustache } from './utils';
import { BalenaSdk } from '../../api-utils';
import { DeviceType } from '.';
import { memo, useEffect, useState } from 'react';
import { MUILinkWithTracking, Material } from '@balena/ui-shared-components';

const { Box, List, ListItem, ListItemIcon, Tab, Tabs, Typography } = Material;

export type OsOptions = ReturnType<typeof getUserOs>;

export const osTitles: Record<OsOptions, string> = {
	windows: 'Windows',
	osx: 'MacOS',
	linux: 'Linux',
	unknown: 'Unknown',
};

export const getUserOs = () => {
	const platform = window.navigator.platform.toLowerCase();
	if (platform.includes('win')) {
		return 'windows';
	}

	if (platform.includes('mac')) {
		return 'osx';
	}

	if (platform.includes('x11') || platform.includes('linux')) {
		return 'linux';
	}

	return 'unknown';
};

const osTabIndices: Record<OsOptions, number> = {
	windows: 0,
	osx: 1,
	linux: 2,
	unknown: 0,
};

const osTabNames = invert(osTabIndices) as Record<string, OsOptions>;

export const ApplicationInstructions = memo(
	({
		deviceType,
		templateData,
	}: {
		deviceType: DeviceType;
		templateData: { dockerImage: string };
	}) => {
		const [currentOs, setCurrentOs] = useState<OsOptions>(getUserOs());

		const instructions = deviceType.instructions;
		const hasOsSpecificInstructions = !Array.isArray(instructions);
		const normalizedOs = currentOs === 'unknown' ? 'linux' : currentOs;

		useEffect(() => {
			if (hasOsSpecificInstructions && instructions) {
				const oses = Object.keys(instructions) as unknown as OsOptions;
				if (!oses.includes(currentOs) && oses.length > 0) {
					setCurrentOs(oses[0] as OsOptions);
				}
			}
		}, [currentOs, setCurrentOs, instructions, hasOsSpecificInstructions]);

		if (!deviceType || !instructions) {
			return (
				<Typography variant="body1">
					Instructions for this application are not currently available. Please
					try again later.
				</Typography>
			);
		}

		const interpolatedInstructions = (
			hasOsSpecificInstructions
				? (instructions as BalenaSdk.DeviceTypeJson.DeviceTypeInstructions)[
						normalizedOs
				  ]
				: (instructions as string[])
		).map((instruction) =>
			interpolateMustache(
				templateData,
				instruction.replace(/<a/, '<a target="_blank"'),
			),
		);

		const hasConfigDownloadOnly =
			deviceType.yocto?.deployArtifact === 'docker-image';

		const finalInstructions = [
			hasConfigDownloadOnly
				? 'Use the form on the left to download a configuration for your new device.'
				: 'Use the form on the left to configure and download balenaOS for your new device.',
			...interpolatedInstructions,
			'Your device should appear in your application dashboard within a few minutes. Have fun!',
		];

		return (
			<Box display="flex" flexDirection="column" alignItems="flex-start">
				<Typography variant="h5" mb={3}>
					Instructions
				</Typography>

				{hasOsSpecificInstructions && (
					<Box mb={3}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs
								value={osTabIndices[currentOs]}
								onChange={(_event, value) =>
									setCurrentOs(osTabNames[value.toString()])
								}
								aria-label="os tabs"
							>
								{(Object.keys(instructions) as OsOptions[]).map((os) => {
									return <Tab key={os} label={osTitles[os]} />;
								})}
							</Tabs>
						</Box>
					</Box>
				)}

				<InstructionsList instructions={finalInstructions} />

				<Box mt={4}>
					<Typography>
						For more details please refer to our{' '}
						<MUILinkWithTracking
							target="_blank"
							href={`https://www.balena.io/docs/learn/getting-started/${deviceType.slug}/nodejs/`}
						>
							Getting Started Guide
						</MUILinkWithTracking>
						.
					</Typography>
				</Box>
			</Box>
		);
	},
);

interface InstructionsItemProps {
	node: any;
	index: number;
}

interface InstructionsListProps {
	instructions: any[];
}

const InstructionsItem = (props: InstructionsItemProps) => {
	const { node, index } = props;

	const hasChildren = has(node, 'children');
	let text = null;

	if (typeof node === 'string') {
		text = node;
	}

	if (node?.text) {
		text = node.text;
	}

	return (
		<ListItem>
			<ListItemIcon>
				<Box
					sx={{
						borderRadius: '50%',
						backgroundColor: 'info.main',
						width: '30px',
						height: '30px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						color: 'white',
						fontWeight: 'bold',
					}}
				>
					{index + 1}
				</Box>
			</ListItemIcon>
			<span dangerouslySetInnerHTML={{ __html: text }} />

			{hasChildren && (
				<List>
					{(node.children as any[]).map((item, i) => {
						return <InstructionsItem key={i} node={item} index={i} />;
					})}
				</List>
			)}
		</ListItem>
	);
};

const InstructionsList = (props: InstructionsListProps) => {
	const { instructions } = props;

	return (
		// TODO: On 13px the line height is calculated as 19.5px, which breaks the alignment of the number inside the list item due to rounding.
		// Remove custom font size once fixed in rendition https://github.com/balena-io-modules/rendition/issues/1025
		<List>
			{instructions.map((item, i) => {
				return <InstructionsItem key={`${item}_${i}`} node={item} index={i} />;
			})}
		</List>
	);
};

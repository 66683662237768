import * as BalenaSdk from 'balena-sdk';
export type { BalenaSdk };

export const API_ENDPOINT =
	process.env.REACT_APP_API_ENDPOINT || 'https://api.balena-cloud.com';

export const sdk = BalenaSdk.getSdk({
	apiUrl: API_ENDPOINT,
	isBrowser: true,
});

export const getExpanded = <T>(obj: BalenaSdk.OptionalNavigationResource<T>) =>
	(Array.isArray(obj) && obj[0]) || undefined;

export const getExpandedProp = <T, K extends keyof T>(
	obj: BalenaSdk.OptionalNavigationResource<T>,
	key: K,
) => (Array.isArray(obj) && obj[0] && obj[0][key]) || undefined;

export const printConsoleGreeting = () => {
	const ascii = `🅱🅰🅻🅴🅽🅰🅷🆄🅱`;
	const greeting = `
${ascii}
Welcome to balenaHub

SDK v${sdk.version}

The SDK is available as "window.sdk".
`;

	console.log(greeting);
	// @ts-expect-error
	window.sdk = sdk;
};

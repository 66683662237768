import { BalenaSdk, sdk } from '../../api-utils';

export const getFleetForGood = async (id: number) => {
	const options: BalenaSdk.PineOptionsWithSelect<BalenaSdk.Application> = {
		$select: ['id', 'app_name', 'is_of__class', 'is_stored_at__repository_url'],
		$expand: {
			is_for__device_type: {
				$select: ['slug'],
			},
			public_organization: {
				$select: ['name'],
			},
			should_be_running__release: {
				$select: ['contract', 'note'],
				$filter: {
					status: 'success',
					is_final: true,
				},
			},
		},
	};
	return await sdk.models.application.get(id, options);
};

export const getPublicDevices = (
	communityId: number,
): Promise<BalenaSdk.PublicDevice[]> => {
	return sdk.pine.get<BalenaSdk.PublicDevice>({
		resource: 'public_device',
		options: {
			$select: ['id', 'latitude', 'longitude'],
			$filter: {
				belongs_to__application: communityId,
				was_recently_online: true,
			},
		},
	});
};

export const getDeviceTypeJsons = (): Promise<
	BalenaSdk.DeviceTypeJson.DeviceType[]
> => {
	return sdk.models.config.getDeviceTypes();
};

import { useEffect, useState } from 'react';
import {
	Truncate,
	Material,
	IconsMaterial,
} from '@balena/ui-shared-components';

const {
	Checkbox,
	Radio,
	Rating,
	InputLabel,
	TextField,
	Box,
	Typography,
	Collapse,
	Button,
	useMediaQuery,
} = Material;

const { ArrowDropDown, ArrowDropUp, FilterList, Close } = IconsMaterial;

export type ReturnFilters = {
	[key: string]: any;
};

export enum FilterType {
	keyword = 'keyword',
	checkbox = 'checkbox',
	radio = 'radio',
	rating = 'rating',
}

type KeywordType = {
	slug: string;
	title: string;
	type: FilterType.keyword;
	value: string | undefined;
};

type CheckboxType = {
	slug: string;
	title: string;
	type: FilterType.checkbox | FilterType.radio;
	options: Array<{
		label: string;
		value: string | number;
		checked: boolean;
	}>;
};

type RadioType = {
	slug: string;
	title: string;
	type: FilterType.radio;
	options: Array<{
		label: string;
		value: string | number;
		checked: boolean;
	}>;
};

type RatingType = {
	slug: string;
	title: string;
	type: FilterType.rating;
	value: number | null;
};

export type Filter = KeywordType | CheckboxType | RadioType | RatingType;

const generateInitialFilters = (filters: Filter[]) => {
	const filtersMatrix = filters.map((filter) => {
		if ('options' in filter) {
			const options = filter.options
				.filter((option) => option.checked)
				.map((option) => option.value);
			return [filter.slug, options];
		}
		return [filter.slug, filter.value];
	});

	return Object.fromEntries(filtersMatrix);
};

const generateIntialShowMore = (filters: Filter[]) => {
	const showMoreMatrix = filters
		.filter((filter) => filter.type === FilterType.checkbox)
		.map((filter) => [filter.slug, false]);

	return Object.fromEntries(showMoreMatrix);
};

const FiltersContainer: React.FC<
	React.PropsWithChildren<{ isMobile: boolean }>
> = ({ isMobile, children }) => {
	const [showFilters, setShowFilters] = useState(false);
	return !isMobile ? (
		<Box maxWidth={300}>
			<Typography variant="h5" fontWeight={600} mb="50px" mt={4}>
				Filter
			</Typography>
			{children}
		</Box>
	) : (
		<Collapse in={showFilters} collapsedSize={35}>
			<Box>
				<Box display="flex" justifyContent="space-between">
					<Button
						color="secondary"
						size="small"
						variant="text"
						onClick={() => setShowFilters(!showFilters)}
						sx={{ minHeight: 0, minWidth: 0, px: 0 }}
					>
						<FilterList /> Filters
					</Button>
					{showFilters && (
						<Button
							color="secondary"
							variant="text"
							sx={{ minHeight: 0, minWidth: 0, px: 0 }}
							onClick={() => setShowFilters(!showFilters)}
						>
							<Close />
						</Button>
					)}
				</Box>
				{showFilters && children}
			</Box>
		</Collapse>
	);
};

interface FiltersProps {
	filters: Filter[];
	onChange: ((data: ReturnFilters) => void) | undefined;
}

export const Filters: React.FC<FiltersProps> = ({ filters, onChange }) => {
	const isMobile = useMediaQuery<Material.Theme>((theme) =>
		theme.breakpoints.down('md'),
	);

	const [filtersValue, setFiltersValue] = useState<{
		[key: string]: string | number | Array<string | number> | undefined | null;
	}>(generateInitialFilters(filters));

	const [showMore, setShowMore] = useState<{
		[key: string]: boolean;
	}>(generateIntialShowMore(filters));

	const handleSimpleOnChange = (
		filter: Filter,
		value: string | number | undefined | null,
	) => {
		setFiltersValue((oldState) => ({
			...oldState,
			[filter.slug]: value,
		}));
	};

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => onChange?.(filtersValue), 400);
		return () => clearTimeout(delayDebounceFn);
	}, [filtersValue, onChange]);

	return (
		<FiltersContainer isMobile={isMobile}>
			{filters.map((filter, index) => {
				if (filter.type === FilterType.keyword) {
					return (
						<Box my={3} key={index}>
							<InputLabel sx={{ marginBottom: 2 }}>{filter.title}</InputLabel>
							<TextField
								size="small"
								fullWidth={true}
								value={filtersValue[filter.slug]}
								onChange={(e) => handleSimpleOnChange(filter, e.target.value)}
							/>
						</Box>
					);
				}
				if (filter.type === FilterType.checkbox) {
					const filterValue = filtersValue[filter.slug] as string[] | undefined;
					return (
						<Box my={3} key={index}>
							<InputLabel>{filter.title}</InputLabel>
							<Collapse in={showMore[filter.slug]} collapsedSize={300}>
								<Box display="flex" flexDirection="column">
									{filter.options.map((option, index) => (
										<Box display="flex" alignItems="center" key={index}>
											<Checkbox
												name={option.label}
												value={option.value}
												checked={filterValue?.some((value) => {
													return value === option.value;
												})}
												onChange={(_event, checked) => {
													setFiltersValue((oldState) => {
														let value = (oldState[filter.slug] ?? []) as Array<
															string | number
														>;
														if (checked) {
															value.push(option.value);
														} else {
															value = value.filter((v) => v !== option.value);
														}
														return {
															...oldState,
															[filter.slug]: value,
														};
													});
												}}
											/>
											<Typography variant="body2">
												<Truncate tooltip lineCamp={1}>
													{option.label}
												</Truncate>
											</Typography>
										</Box>
									))}
								</Box>
							</Collapse>
							<Box display="flex" justifyContent="center">
								<Button
									onClick={() =>
										setShowMore((oldState) => ({
											...oldState,
											[filter.slug]: !oldState[filter.slug],
										}))
									}
								>
									{showMore[filter.slug] ? 'Less' : 'More'}
									{showMore[filter.slug] ? <ArrowDropUp /> : <ArrowDropDown />}
								</Button>
							</Box>
						</Box>
					);
				}
				if (filter.type === FilterType.rating) {
					const filterValue = filtersValue[filter.slug] as number | undefined;
					return (
						<Box my={3} key={index}>
							<InputLabel shrink htmlFor="bootstrap-input">
								{filter.title}
							</InputLabel>
							<Rating
								value={filterValue}
								onChange={(_event, value) =>
									handleSimpleOnChange(filter, value)
								}
							/>
						</Box>
					);
				}

				return <Radio></Radio>;
			})}
		</FiltersContainer>
	);
};
